import React, { useEffect, useState } from "react";
import { changeSettingList, getMultiplierListApi, getPackageListApi } from "../../apiCaller/services";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";

const Setting = (props) => {

    const [document, setDocument] = useState("");
    const [smallPackage, setSmallPackage] = useState("");
    const [mediumPackage, setMediumPackage] = useState("");
    const [shortDistance, setShortDistance] = useState(0);
    const [mediumDistance, setMediumDistance] = useState(0);
    const [longDistance, setLongDistance] = useState(0);
    const accessToken = localStorage.getItem("accessToken");
    const [errDocument, setErrDocument] = useState("");
    const [errSmallPkg, setErrSmallPkg] = useState("");
    const [errMediumPkg, setErrMediumPkg] = useState("");

    const fetchPackageList = async () => {
        const PackageList = await getPackageListApi(accessToken);
        PackageList.map((item) => {
            switch (item.pkId) {
                case 1: return setDocument(item.pkPrice);
                case 2: return setSmallPackage(item.pkPrice);
                case 3: return setMediumPackage(item.pkPrice);
                default: return '';
            }
        })
    }

    const fetchMultiplierList = async () => {
        const MultiplierList = await getMultiplierListApi(accessToken);
        MultiplierList.map((item) => {
            switch (item.id) {
                case 1: return setShortDistance(item.multiplier);
                case 2: return setMediumDistance(item.multiplier);
                case 3: return setLongDistance(item.multiplier);
                default: return '';
            }
        })
    }

    useEffect(() => {
        fetchPackageList();
        fetchMultiplierList();
    }, []);

    const handleSubmit = async () => {
        if (document < 1000 || document > 999999 || smallPackage < 1000 || smallPackage > 999999 || mediumPackage < 1000 || mediumPackage > 999999) {
            if (document < 1000 || document > 999999)
                setErrDocument("Giá vận chuyển giấy tờ nằm trong khoảng từ 1.000 đến 999.999");
            if (smallPackage < 1000 || smallPackage > 999999)
                setErrSmallPkg("Giá vận chuyển giấy tờ nằm trong khoảng từ 1.000 đến 999.999");
            if (mediumPackage < 1000 || mediumPackage > 999999)
                setErrMediumPkg("Giá vận chuyển giấy tờ nằm trong khoảng từ 1.000 đến 999.999");
        }
        else {
            setErrDocument("");
            setErrSmallPkg("");
            setErrMediumPkg("");
            const data = {
                paperPrice: document,
                smallParcelprice: smallPackage,
                mediumParcelPrice: mediumPackage,
                mutiplier1: shortDistance,
                mutiplier2: mediumDistance,
                mutiplier3: longDistance
            };
            if (window.confirm("Bạn có muốn cập nhật Cài đặt hệ thống không?")) {
                try {
                    const res = await changeSettingList(data, accessToken);
                    if (res?.status === 200) {
                        toast.success("Cập nhật cài đặt hệ thống thành công!")
                    } else {
                        toast.error("Cập nhật cài đặt hệ thống thất bại!")
                    }
                } catch {

                }
            }
            fetchPackageList();
            fetchMultiplierList();
        }
    }

    const handlePriceChange = (value) => {
        const min = 0;
        const max = 999999;
        value = Number(value.replace(".", ""));
        value = Math.max(Number(min), Math.min(Number(max), Number(value)));
        return value;
    }

    const handleMultiplierChange = (value) => {
        const min = 1;
        const max = 10;
        value = Math.max(Number(min), Math.min(Number(max), Number(value)));
        return value;
    }

    const numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    };

    return (
        <>
            <section>
                <div className="mt-5 bg-white">
                    <div className="row p-5">
                        <h1>BẢNG GIÁ</h1>
                        <div className="pb-3 edit-modal col-md-4">
                            <label htmlFor="document" className="edit-label-modal mb-3 fw-bold">GIẤY TỜ</label>
                            <input
                                type="text"
                                className="form-control w-75"
                                id="document"
                                name="document"
                                autoComplete="off"
                                onChange={(e) => {
                                    setDocument(handlePriceChange(e.target.value));
                                }}
                                value={numberWithCommas(document)}
                                aria-describedby="document"
                                style={{ padding: '8px 12px' }}
                                min={1000}
                                max={999999}
                                step={1000}
                                required
                            />
                            {errDocument !== "" ? <p className="text-danger">{errDocument}</p> : ""}
                        </div>
                        <div className="pb-3 edit-modal col-md-4">
                            <label htmlFor="smallpackage" className="edit-label-modal mb-3 fw-bold">BƯU KIỆN NHỎ</label>
                            <input
                                type="text"
                                className="form-control w-75"
                                id="smallpackage"
                                name="smallpackage"
                                autoComplete="off"
                                onChange={(e) => {
                                    setSmallPackage(handlePriceChange(e.target.value));
                                }}
                                value={numberWithCommas(smallPackage)}
                                aria-describedby="smallpackage"
                                style={{ padding: '8px 12px' }}
                                min={1000}
                                max={999999}
                                step={1000}
                                required
                            />
                            {errSmallPkg !== "" ? <p className="text-danger">{errSmallPkg}</p> : ""}
                        </div>
                        <div className="pb-3 edit-modal col-md-4">
                            <label htmlFor="mediumpackage" className="edit-label-modal mb-3 fw-bold">BƯU KIỆN VỪA</label>
                            <input
                                type="text"
                                className="form-control w-75"
                                id="mediumpackage"
                                name="mediumpackage"
                                autoComplete="off"
                                onChange={(e) => setMediumPackage(handlePriceChange(e.target.value))}
                                value={numberWithCommas(mediumPackage)}
                                aria-describedby="mediumpackage"
                                style={{ padding: '8px 12px' }}
                                min={1000}
                                max={999999}
                                step={1000}
                                required
                            />
                            {errMediumPkg !== "" ? <p className="text-danger">{errMediumPkg}</p> : ""}
                        </div>
                        <h1>HỆ SỐ KHOẢNG CÁCH</h1>
                        <div className="pb-3 edit-modal col-md-4">
                            <label htmlFor="shortdistance" className="edit-label-modal mb-3 fw-bold">0 - 5</label>
                            <input
                                type="number"
                                className="form-control w-75"
                                id="shortdistance"
                                name="shortdistance"
                                autoComplete="off"
                                onChange={(e) => setShortDistance(handleMultiplierChange(e.target.value))}
                                value={shortDistance}
                                aria-describedby="shortdistance"
                                style={{ padding: '8px 12px' }}
                                min={1}
                                max={10}
                                step={0.01}
                                required
                            />
                        </div>
                        <div className="pb-3 edit-modal col-md-4">
                            <label htmlFor="mediumdistance" className="edit-label-modal mb-3 fw-bold">5 - 12</label>
                            <input
                                type="number"
                                className="form-control w-75"
                                id="mediumdistance"
                                name="mediumdistance"
                                autoComplete="off"
                                onChange={(e) => setMediumDistance(handleMultiplierChange(e.target.value))}
                                value={mediumDistance}
                                aria-describedby="mediumdistance"
                                style={{ padding: '8px 12px' }}
                                min={1}
                                max={10}
                                step={0.01}
                                required
                            />
                        </div>
                        <div className="pb-3 edit-modal col-md-4">
                            <label htmlFor="longdistance" className="edit-label-modal mb-3 fw-bold">10 - longer</label>
                            <input
                                type="number"
                                className="form-control w-75"
                                id="longdistance"
                                name="longdistance"
                                autoComplete="off"
                                onChange={(e) => setLongDistance(handleMultiplierChange(e.target.value))}
                                value={longDistance}
                                aria-describedby="longdistance"
                                style={{ padding: '8px 12px' }}
                                min={1}
                                max={10}
                                step={0.01}
                                required
                            />
                        </div>
                        <div className="col-md-9 col-7"></div>
                        <Button
                            variant="primary"
                            className="col-md-2 col-4"
                            style={{ fontSize: '14px' }}
                            onClick={
                                handleSubmit
                            }
                        >
                            Lưu thay dổi
                        </Button>
                        <div className="col-1"></div>
                    </div>
                </div >
            </section>
        </>
    );

}

export default Setting;