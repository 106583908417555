import _ from "lodash";
import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";

const WarehouseDetail = (props) => {

    const { show, setShow, warehouseDetail } = props;
    const [whId, setWhId] = useState("");
    const [whName, setWhName] = useState("");
    const [whAddress, setWhAddress] = useState("");

    useEffect(() => {
        if (!_.isEmpty(warehouseDetail)) {
            setWhId(warehouseDetail?.warehouseId);
            setWhName(warehouseDetail?.warehouseName);
            setWhAddress(`${warehouseDetail?.house}, ${warehouseDetail?.ward}, ${warehouseDetail?.district}, ${warehouseDetail?.city}`)
        }
    }, [warehouseDetail])

    return (
        <>
            <Modal show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Chi tiết kho</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="">
                        <section>
                            <div className="mb-3 input-modal">
                                <label htmlFor="whId" className="input-label-modal mb-3">MÃ KHO</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="whId"
                                    name="whId"
                                    autoComplete="off"
                                    value={whId}
                                    aria-describedby="whId"
                                    style={{ padding: '8px 12px' }}
                                    disabled
                                />
                            </div>
                            <div className="mb-3 input-modal">
                                <label htmlFor="name" className="input-label-modal mb-3">TÊN KHO</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="name"
                                    name="name"
                                    autoComplete="off"
                                    value={whName}
                                    aria-describedby="name"
                                    style={{ padding: '8px 12px' }}
                                    disabled
                                />
                            </div>
                            <div className="mb-3 input-modal">
                                <label htmlFor="address" className="input-label-modal mb-3">ĐỊA CHỈ</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="address"
                                    name="address"
                                    autoComplete="off"
                                    value={whAddress}
                                    aria-describedby="address"
                                    style={{ padding: '8px 12px' }}
                                    disabled
                                />
                            </div>
                        </section>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => setShow(false)}>
                        Đóng
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )

}

export default WarehouseDetail;