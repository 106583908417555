import axios from './axios';

const loginApi = (user, pwd) => {
    return axios.post("/authentication/login", { username: user, password: pwd });
}

const getOtpApi = (email) => {
    return axios.post("/send-mail/send-verify-code", {email: email});
}

const changePasswordApi = (email, pwd) => {
    return axios.patch("/send-mail/reset-password", {email: email, password: pwd})
}

const getAllAccountApi = () => {
    return axios.get("/account/getAll",);
}

const getAccountDetailApi = (accessToken) => {
    return axios.get("/staff/profile", { headers: { Authorization: `Bearer ${accessToken}` } });
}

const resetPasswordApi = (data, accessToken) => {
    return axios.put("/account/updatePaasword", data, { headers: { Authorization: `Bearer ${accessToken}` } })
}

const updateProfileApi = (data, accessToken) => {
    return axios.post("/staff/profile/update", data, { headers: { Authorization: `Bearer ${accessToken}` } });
}

const getCitiesApi = () => {
    return axios.get("/address/getAllCity");
}

const getDistrictsByCityApi = (cityId) => {
    return axios.get(`/address/getDistrictByCityId?city_id=${cityId}`);
}

const getWardsByDistrictApi = (districtId) => {
    return axios.get(`/address/getWardByDitrictId?district_id=${districtId}`);
}

const getOrderListApi = (page, accessToken) => {
    return axios.get(`/staff/order/all?pageNo=${page}`, { headers: { Authorization: `Bearer ${accessToken}` } });
}

const getOrderDetailApi = (orderId, accessToken) => {
    return axios.get(`/staff/order/detail?orderId=${orderId}`, { headers: { Authorization: `Bearer ${accessToken}` } });
}

const createQrApi = (num, accessToken) => {
    return axios.post("/qr-code/create", { quantity: Number(num) }, { headers: { Authorization: `Bearer ${accessToken}` } });
}

const getQrListApi = (page, accessToken) => {
    return axios.get(`/qr-code/all?pageNo=${page}`, { headers: { Authorization: `Bearer ${accessToken}` } });
}

const getQrDetailApi = (qrValue, accessToken) => {
    return axios.get(`/qr-code/detail?codeValue=${qrValue}`, { headers: { Authorization: `Bearer ${accessToken}` } });
}

const getRequestListApi = (page, accessToken) => {
    return axios.get(`/request/staff/requests-staff?pageNo=${page}`, { headers: { Authorization: `Bearer ${accessToken}` } });
}

const updateRequestSttApi = (recordId, requestStt, accessToken) => {
    return axios.put("/request/request/staff/order-update", { recordId: recordId, requestStatus: requestStt }, { headers: { Authorization: `Bearer ${accessToken}` } })
}

const updateRequestNoteApi = (data, accessToken) => {
    return axios.post("/request/update", data, { headers: { Authorization: `Bearer ${accessToken}` } })
}

const getShipperListApi = (accessToken) => {
    return axios.get("/shipper/shippers", { headers: { Authorization: `Bearer ${accessToken}` } })
}

const getWarehouseListStaffApi = (accessToken) => {
    return axios.get("/warehourse/staff/warehouses", { headers: { Authorization: `Bearer ${accessToken}` } })
}

const createRequestApi = (whTo, shipper, note, accessToken) => {
    return axios.post("/request/request-create/transit", { warehouseTo: Number(whTo), transitShiper: Number(shipper), note: note }, { headers: { Authorization: `Bearer ${accessToken}` } })
}

const assignShipperToOrder = (orderId, shipperId, accessToken) => {
    return axios.put("/order/staff-order/quick-update-order", { orderId: orderId, shiperrId: shipperId }, { headers: { Authorization: `Bearer ${accessToken}` } })
}

const getPackageListApi = (accessToken) => {
    return axios.get("/admin/admin/packages-type", { headers: { Authorization: `Bearer ${accessToken}` } })
}

const getStaffInWarehouseApi = (page, accessToken) => {
    return axios.get(`/managercrud/getAllStaff?pageNo=${page}`, { headers: { Authorization: `Bearer ${accessToken}` } })
}

const getStaffDetailApi = (staffId, accessToken) => {
    return axios.get(`admin/admin/staff/detail?staffId=${staffId}`, { headers: { Authorization: `Bearer ${accessToken}` } })
}

const getAllCustomerInWarehouseApi = (page, accessToken) => {
    return axios.get(`/order/staff/warehoue/customers?pageNo=${page}`, { headers: { Authorization: `Bearer ${accessToken}` } })
}

const getCustomerInWarehouseApi = (page, accessToken) => {
    return axios.get(`order/staff/warehoue/customers?pageNo=${page}`, { headers: { Authorization: `Bearer ${accessToken}` } })
}

const getCustomerDetailApi = (cusId, accessToken) => {
    return axios.get(`/customer/admin-manager/customer-detail?customerId=${cusId}`, { headers: { Authorization: `Bearer ${accessToken}` } })
}

const changeSettingList = (data, accessToken) => {
    return axios.post("/admin/price-mutiplier/update", 
    data, 
    { headers: { Authorization: `Bearer ${accessToken}` } })
}

const getMultiplierListApi = (accessToken) => {
    return axios.get("/admin/admin/price-mutiplier", { headers: { Authorization: `Bearer ${accessToken}` } })
}

const getWarehouseListAdminApi = (page, accessToken) => {
    return axios.get(`/warehourse/getAllWarehouse?pageNo=${page}`, { headers: { Authorization: `Bearer ${accessToken}` } })
}

const getWarehouseDetailApi = (whId, accessToken) => {
    return axios.get(`warehourse/getDetailWarehouse?warehouse_id=${whId}`, { headers: { Authorization: `Bearer ${accessToken}` } })
}

const getAdminDashBoard = (accessToken) => {
    return axios.get("/report/admin/dashboard", { headers: { Authorization: `Bearer ${accessToken}` } })
}

const getManagerDashBoard = (accessToken) => {
    return axios.get("/report/manager/dashboard", { headers: { Authorization: `Bearer ${accessToken}` } })
}

const getSearchOrderApi = (page, searchValue, orderStt, accessToken) => {
    return axios.get(`/staff/order/all-filters?pageNo=${page}&seachValue=${searchValue}&orderStatus=${orderStt}`,{ headers: { Authorization: `Bearer ${accessToken}` } } )
}

const adminGetAllStaffApi = (page, accessToken) => {
    return axios.get(`/admin/admin/staffs?pageNo=${page}`, { headers: { Authorization: `Bearer ${accessToken}` } })
}

const adminAssignStaffToManagerApi = (staffId, whId, accessToken) => {
    return axios.post("/admin/admin/staff/role-update", {staffId: staffId, warehouseId: whId}, { headers: { Authorization: `Bearer ${accessToken}` } })
}

const adminAreaReportApi = (page, accessToken) => {
    return axios.get(`/report/admin/area/detail?pageNo=${page}`, { headers: { Authorization: `Bearer ${accessToken}` } })
}

const adminRevenueGraphReportApi = (accessToken) => {
    return axios.get("/report/admin/revenue/revenue-graph", { headers: { Authorization: `Bearer ${accessToken}` } })
}

const adminRevenueTableReportApi = (page, month, accessToken) => {
    return axios.get(`/report/admin/revenue/warehouse-revenue/table?month=${month}&pageNo=${page}`, { headers: { Authorization: `Bearer ${accessToken}` } })
}

const adminOrderReportGraphApi = (accessToken) => {
    return axios.get("/report/admin/order/order-graph", { headers: { Authorization: `Bearer ${accessToken}` } })
}

const adminOrderReportTableApi =  (page, month, accessToken) => {
    return axios.get(`/report/admin/order/warehouse-order/table?month=${month}&pageNo=${page}`, { headers: { Authorization: `Bearer ${accessToken}` } })
}

const adminCustomerReportGraphApi = (accessToken) => {
    return axios.get("/report/admin/customer/customer-graph", { headers: { Authorization: `Bearer ${accessToken}` } })
}

const adminCustomerReportTableApi = (page, month, accessToken) => {
    return axios.get(`/report/admin/customer/customer-table?pageNo=${page}&month=${month}`, { headers: { Authorization: `Bearer ${accessToken}` } })
}

const adminStaffReportTableApi = (page, month, accessToken) => {
    return axios.get(`/report/admin/staff/table?month=${month}&pageNo=${page}`, { headers: { Authorization: `Bearer ${accessToken}` } })
}

const adminAreaReportTableApi = (page, month, accessToken) => {
    return axios.get(`/report/admin/revenue/area-revenue/table?month=${month}&pageNo=${page}`, { headers: { Authorization: `Bearer ${accessToken}` } })
}

const managerRevenueReportGraphApi = (accessToken) => {
    return axios.get("/report/manager/revenue/revenue-graph", { headers: { Authorization: `Bearer ${accessToken}` } })
}

const managerOrderReportGraphApi = (accessToken) => {
    return axios.get("/report/manager/order/order-graph", { headers: { Authorization: `Bearer ${accessToken}` } })
}

const managerOrderReportTableApi = (page, month, accessToken) => {
    return axios.get(`/report/manager/order/order-table?pageNo=${page}&month=${month}`, { headers: { Authorization: `Bearer ${accessToken}` } })
}

const managerStaffReportTableApi = (page, month, accessToken) => {
    return axios.get(`/report/manager/staff/staff-table?pageNo=${page}&month=${month}`, { headers: { Authorization: `Bearer ${accessToken}` } })
}

const getAllStaffApi = (page, accessToken) => {
    return axios.get(`/admin/admin/staffs?pageNo=${page}`, { headers: { Authorization: `Bearer ${accessToken}` } })
}

const createStaffApi = (data, accessToken) => {
    return axios.post("/admin/admin/create-staff", data, { headers: { Authorization: `Bearer ${accessToken}` } })
}

export { 
    loginApi, 
    getOtpApi, 
    changePasswordApi, 
    getAllAccountApi,
    getAccountDetailApi, 
    resetPasswordApi, 
    updateProfileApi, 
    getOrderListApi, 
    getOrderDetailApi, 
    createQrApi, 
    getQrListApi, 
    getQrDetailApi, 
    getRequestListApi, 
    updateRequestSttApi, 
    updateRequestNoteApi, 
    getShipperListApi, 
    getWarehouseListStaffApi, 
    getStaffDetailApi, 
    createRequestApi, 
    assignShipperToOrder, 
    getPackageListApi, 
    getStaffInWarehouseApi, 
    getAllCustomerInWarehouseApi, 
    getCustomerInWarehouseApi, 
    getCustomerDetailApi, 
    changeSettingList, 
    getMultiplierListApi, 
    getWarehouseListAdminApi, 
    getWarehouseDetailApi, 
    getAdminDashBoard, 
    getManagerDashBoard, 
    getSearchOrderApi, 
    getCitiesApi, 
    getDistrictsByCityApi, 
    getWardsByDistrictApi, 
    adminGetAllStaffApi, 
    adminAssignStaffToManagerApi, 
    adminAreaReportApi, 
    adminRevenueGraphReportApi, 
    adminRevenueTableReportApi, 
    adminOrderReportGraphApi, 
    adminOrderReportTableApi, 
    adminCustomerReportGraphApi, 
    adminCustomerReportTableApi, 
    adminStaffReportTableApi, 
    adminAreaReportTableApi, 
    managerRevenueReportGraphApi, 
    managerOrderReportGraphApi, 
    managerOrderReportTableApi, 
    managerStaffReportTableApi, 
    getAllStaffApi, 
    createStaffApi, 
};
