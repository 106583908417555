import { useRef, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { createQrApi } from '../../apiCaller/services';
import Notification from "../notification/Notification";

const CreateQr = (props) => {

    const { show, setShow, page } = props;

    const numRef = useRef();
    const [num, setNum] = useState(0);
    const [isSuccess, setIsSuccess] = useState(false);
    const [errMsg, setErrMsg] = useState('no error');

    const accessToken = localStorage.getItem("accessToken");

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(accessToken);
        if (window.confirm(`Bạn có chắc muốn tạo ${num} QR code?`)) {
            try {
                const res = await createQrApi(num, accessToken);
                if (res) {
                    setIsSuccess(true);
                    props.fetchQr(page+1);
                }
            } catch (err) {
                console.log("error: ",err.response);
                setErrMsg(`Error: ${err.response}`);
            }
        }
        else {
            
        }

    };

    const handleNumberChange = (e) => {
        let { value, min, max } = e.target;
        value = Math.max(Number(min), Math.min(Number(max), Number(value)));
        setNum(value);
    }

    const handleClose = () => {
        setShow(false);
        setNum(0);
        setIsSuccess(false);
        // props.fetchQr(1);
    }

    return (
        <>
            <Modal
                show={show}
                backdrop="static"
                onHide={handleClose}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Tạo QR</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="">
                        <section>
                            {isSuccess ? <Notification message={num} /> : ''}
                            <div className="mb-3 input-modal">
                                <label htmlFor="qrnumber" className="input-label-modal mb-3">NHẬP SỐ LƯỢNG MÃ QR MUỐN TẠO <span style={{ color: 'red' }}>*</span></label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="qrnumber"
                                    name="qrnumber"
                                    ref={numRef}
                                    autoComplete="off"
                                    onChange={handleNumberChange}
                                    value={num}
                                    aria-describedby="qrnumber"
                                    placeholder="Nhập số lượng mã"
                                    style={{ padding: '8px 12px' }}
                                    min="1"
                                    max="50"
                                    required
                                />
                            </div>
                        </section>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={handleClose}>
                        Huỷ bỏ
                    </Button>
                    <Button type="submit" variant="primary" onClick={handleSubmit}>
                        Tạo QR
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    )

}

export default CreateQr;