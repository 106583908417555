import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";

const Information = (props) => {

    const { show, setShow, showEdit, setShowEdit, user } = props;
    const [userName, setUserName] = useState("");
    const [email, setEmail] = useState("");
    const [address, setAddress] = useState("");
    const [phone, setPhone] = useState("");
    const [wh, setWh] = useState("");
    const accessToken = localStorage.getItem("accessToken");

    const handleOpenEdit = () => {
        setShow(false);
        setShowEdit(true);
    }

    useEffect(() => {
        setUserName(user?.fullname);
        setEmail(user?.email);
        setAddress(`${user?.address?.house}, ${user?.address?.wardName}, ${user?.address?.districtName}, ${user?.address?.cityName}`);
        setPhone(user?.phone);
        setWh(user?.warehouseName);
    }, []);

    return (
        <>
            <Modal show={show} backdrop="static" onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Thông tin</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mt-4 pt-2 mb-4 pb-2">
                        <div className="rounded shadow mt-4">
                            <div className="p-4 border-bottom">
                                <h5 className="mb-0">Hồ sơ người dùng :</h5>
                            </div>
                            <div className="p-4">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="mb-3">
                                            <label className="form-label">Tên</label>
                                            <input name="name" id="name" type="text" className="form-control" value={userName} disabled />
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="mb-3">
                                            <label className="form-label">Email</label>
                                            <input name="email" id="email" type="email" className="form-control" value={email} disabled />
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="mb-3">
                                            <label className="form-label">Địa chỉ</label>
                                            <input name="email" id="email" type="email" className="form-control" value={address} disabled />
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="mb-3">
                                            <label className="form-label">Số điện thoại</label>
                                            <input name="number" id="number" type="text" className="form-control" value={phone} disabled />
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="mb-3">
                                            <label className="form-label">Kho</label>
                                            <input name="warehouse" id="warehouse" type="text" className="form-control" value={wh} disabled />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={() => setShow(false)}>
                        Đóng
                    </Button>
                    <Button variant="primary" onClick={handleOpenEdit}>
                        Chỉnh sửa thông tin
                    </Button>
                </Modal.Footer>
            </Modal>
        </>

    )
}

export default Information;