import React from "react";
import '../App.css'

const Banner = () => {
    return React.createElement(
        'div',
        {id: 'banner', className: 'w-100 h-100'},
        React.createElement(
            'img',
            {src: './images/h2h.png', alt: 'Banner Background', className: 'h-100 w-100 object-fit-cover'},
            null))
}

export default Banner