import Table from 'react-bootstrap/Table';
import Header from '../../layout/Header';
import Sidebar from '../../layout/SideBar';
import Dropdown from 'react-bootstrap/Dropdown';
import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';

// Be sure to include styles at some point, probably during your bootstraping
import '@trendmicro/react-paginations/dist/react-paginations.css';
import { adminAssignStaffToManagerApi, adminGetAllStaffApi, getStaffDetailApi } from '../../apiCaller/services';
import CreateStaff from '../staffreport/CreateStaff';
import StaffManagementDetail from './StaffManagementDetail';
import { toast } from 'react-toastify';


const StaffManagement = () => {

    const [staffList, setStaffList] = useState([]);
    const accessToken = localStorage.getItem("accessToken");
    const [pageCount, setPageCount] = useState(1);
    const [showDetail, setShowDetail] = useState(false);
    const [showCreate, setShowCreate] = useState(false);
    const [staffDetail, setStaffDetail] = useState([]);
    const [page, setPage] = useState(0);
    const [selectedStaffRole, setSelectedStaffRole] = useState(new Array(0).fill(0));

    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <a
            href=""
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
            style={{ color: '#000000' }}
        >
            {children}
        </a>
    ));

    const fetchStaffList = async (page) => {
        try {
            const staffsData = await adminGetAllStaffApi(page, accessToken);
            setStaffList(staffsData?.mappedData);
            setPageCount(staffsData?.totalpage);
        } catch (err) {

        }
    };

    const fetchStaffDetail = async (staff) => {
        try {
            const staffData = await getStaffDetailApi(staff.staffId, accessToken);
            setStaffDetail(staffData);
        } catch (err) {

        }
    }

    const setStaffToManager = async (item) => {
        if (window.confirm(`Bạn có chắc muốn cho nhân viên ${item?.fullname} lên thành quản lý cho kho ${item?.warehouseName} không?`)) {
            try {
                const res = await adminAssignStaffToManagerApi(Number(item?.staffId), Number(item?.warehouseId), accessToken);
                console.log(res);
                if (res?.status === 200) {
                    toast.success(`Thành công thăng chức cho nhân viên ${item?.fullname} thành quản lý kho ${item?.warehouseName}`);
                    fetchStaffList(page+1);
                } else {
                    toast.error(`Thao tác không thành công!`);
                }
            } catch (err) {
                toast.error("Lỗi thao tác: ", err);
                
            }
        }
    }

    const handlePageClick = (event) => {
        fetchStaffList(+event.selected + 1);
        setPage(+event.selected);
    };

    useEffect(() => {
        fetchStaffList(1);
    }, [])

    let rows2;

    if (staffList && staffList.length < 10) {
        rows2 = (
            <>
                {
                    [...Array(10 - staffList.length)].map((_, i) => (
                        <tr key={staffList.length + i}>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    ))
                }
            </>
        );
    };

    return (
        <section>
            <div className="container-fluid w-100 row p-0 m-0" style={{ backgroundColor: '#F5F5F5' }}>
                <Header />
                <div className="row">
                    <Sidebar />
                    <div className='col-lg-10 col-md-8 col-12 ps-5' style={{ fontSize: '14px', lineHeight: '20px' }}>
                        <div className='my-5'>
                            <h1>Quản lý nhân viên</h1>
                        </div>
                        <div className='w-100 '>
                            <div className='d-flex justify-content-between align-items-center row bg-white list-header p-4'>
                                <h2 className='col-lg-3 col-12'>Danh sách nhân viên</h2>
                                <div className='d-lg-flex flex-row-reverse col-lg-9 py-4'>
                                    <div className='ms-0 ms-lg-4 d-flex justify-content-end'>
                                        <button className='btn btn-danger h-100 p-2' style={{ fontSize: '16px' }} onClick={() => setShowCreate(true)}>+&nbsp;&nbsp;Tạo nhân viên</button>
                                        <CreateStaff
                                            show={showCreate}
                                            setShow={setShowCreate}
                                            fetchStaffList={fetchStaffList}
                                            page={page}
                                        />
                                    </div>
                                    <div className='d-flex justify-content-end mt-3 mt-lg-0'>
                                        <ReactPaginate
                                            breakLabel="..."
                                            nextLabel=">"
                                            onPageChange={handlePageClick}
                                            pageRangeDisplayed={5}
                                            pageCount={pageCount}
                                            previousLabel="<"
                                            renderOnZeroPageCount={null}
                                            marginPagesDisplayed={2}
                                            pageClassName="page-item"
                                            pageLinkClassName="page-link"
                                            previousClassName="page-item"
                                            previousLinkClassName="page-link"
                                            nextClassName="page-item"
                                            nextLinkClassName="page-link"
                                            breakClassName="page-item"
                                            breakLinkClassName="page-link"
                                            containerClassName="pagination"
                                            activeClassName="active"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='bg-white p-4'>
                                <Table responsive bordered striped cellSpacing={0}>
                                    <thead>
                                        <tr>
                                            <th style={{ width: '10%' }}>TÊN NHÂN VIÊN</th>
                                            <th style={{ width: '20%' }}>KHO</th>
                                            <th style={{ width: '20%' }}>EMAIL</th>
                                            <th style={{ width: '15%' }}>SỐ ĐIỆN THOẠI</th>
                                            <th style={{ width: '15%' }}>VAI TRÒ</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {staffList?.map((item, index) => (
                                            <tr height='30px' key={`staff-${index}`}>
                                                <td>{item.fullname}</td>
                                                <td>{item.warehouseName}</td>
                                                <td>
                                                    {item.email}
                                                </td>
                                                <td>
                                                    {item.phone}
                                                </td>
                                                <td className='position-relative'>
                                                    <p className='pe-5'>
                                                        {item.roleName}
                                                    </p>
                                                    <Dropdown className='position-absolute top-0 end-0 pt-2 pe-2'>
                                                        <Dropdown.Toggle as={CustomToggle}>
                                                            <i className="fa-solid fa-ellipsis"></i>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item href=""
                                                                onClick={() => {
                                                                    setShowDetail(true);
                                                                    fetchStaffDetail(item);
                                                                }}
                                                            >
                                                                Xem chi tiết
                                                            </Dropdown.Item>
                                                            <StaffManagementDetail
                                                                show={showDetail}
                                                                setShow={setShowDetail}
                                                                staffDetail={staffDetail}
                                                            />
                                                            {item?.roleId === 3 ? (
                                                                <Dropdown.Item href="" onClick={() => {
                                                                    if (item.roleId === 3) {
                                                                        setStaffDetail(item);
                                                                        setStaffToManager(item);
                                                                    }
                                                                }}
                                                                >
                                                                    Thăng chức
                                                                </Dropdown.Item>
                                                            ): ''}
                                                            {/* <Modal show={showEdit} onHide={() => { }}>
                                                                <Modal.Header closeButton>
                                                                    <Modal.Title>Chỉnh sửa nhân viên</Modal.Title>
                                                                </Modal.Header>
                                                                <Modal.Body>
                                                                    <EditStaff />
                                                                </Modal.Body>
                                                                <Modal.Footer>
                                                                    <Button variant="primary" onClick={() => { }}>
                                                                        Lưu nhân viên
                                                                    </Button>
                                                                </Modal.Footer>
                                                            </Modal> */}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </td>
                                            </tr>
                                        ))}
                                        {rows2}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>

    )
}

export default StaffManagement;