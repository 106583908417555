import Table from 'react-bootstrap/Table';
import Header from '../../layout/Header';
import Sidebar from '../../layout/SideBar';
import Dropdown from 'react-bootstrap/Dropdown';
import React, { useEffect, useRef, useState } from 'react';
import ReactPaginate from 'react-paginate';
import QrDetail from './QrDetail';
import CreateQr from './CreateQr';
import PrintQr from './PrintQr';
import Form from 'react-bootstrap/Form';

// Be sure to include styles at some point, probably during your bootstraping
import '@trendmicro/react-paginations/dist/react-paginations.css';
import { getQrListApi } from '../../apiCaller/services';


const QrList = (props) => {

    const [showModalCreateQr, setShowModalCreateQr] = useState(false);
    const [showModalEditQr, setShowModalEditQr] = useState(false);
    const [showModalQrDetail, setShowModalQrDetail] = useState(false);
    const accessToken = localStorage.getItem("accessToken");
    const [qrList, setQrList] = useState([]);
    const [qrDetail, setQrDetail] = useState({});
    const [pageCount, setPageCount] = useState(1);
    const [page, setPage] = useState(0);

    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <a
            href=""
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
            style={{ color: '#000000' }}
        >
            {children}
        </a>
    ));

    const fetchQr = async (page) => {
        const qrData = await getQrListApi(page, accessToken);
        setQrList(qrData.data);
        setPageCount(qrData.paging.totalPages);
    };

    useEffect(() => {
        fetchQr(1);
    }, []);

    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
        fetchQr(+event.selected + 1);
        setPage(+event.selected);
    };

    let rows2;

    if (qrList && qrList.length < 10) {
        rows2 = (
            <>
                {
                    [...Array(10 - qrList.length)].map((_, i) => (
                        <tr style={{ height: '30px' }} key={qrList.length + i}>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    ))
                }
            </>
        );
    };

    const [selectedItems, setSelectedItems] = useState([]);

    const isButtonDisabled = selectedItems.length === 0;

    const handleSelectOne = (event, itemValue) => {
        if (event.target.checked) {
            setSelectedItems([...selectedItems, itemValue]);
        } else {
            setSelectedItems(selectedItems.filter((value) => value !== itemValue));
        }
    };

    return (
        <section>
            <div className="container-fluid w-100 row p-0 m-0" style={{ backgroundColor: '#F5F5F5' }}>
                <Header />
                <div className="row">
                    <Sidebar />
                    <div className='col-lg-10 col-md-8 col-12 ps-5' style={{ fontSize: '14px', lineHeight: '20px' }}>
                        <div className='my-5'>
                            <h1>Quản lý QR</h1>
                        </div>
                        <div className='w-100 '>
                            <div className='d-flex justify-content-between align-items-center row bg-white list-header p-4'>
                                <h2 className='col-lg-5 col-12'>Danh sách QR</h2>
                                <div className='d-md-flex flex-row-reverse col-lg-7 py-4'>
                                    <div className="ms-0 ms-md-4 d-flex justify-content-end">
                                        <button className='btn btn-danger h-100 p-2' style={{ fontSize: '16px' }} onClick={() => setShowModalCreateQr(true)}>+&nbsp;&nbsp;Tạo QR</button>
                                        <CreateQr
                                            show={showModalCreateQr}
                                            setShow={setShowModalCreateQr}
                                            fetchQr={fetchQr}
                                            page={page}
                                        />
                                    </div>
                                    <div className='d-flex justify-content-end'>
                                        <button disabled={isButtonDisabled} className='btn btn-danger ms ms-4 h-100 p-2 mt-md-0 mt-3' style={{ fontSize: '16px' }} onClick={() => setShowModalEditQr(true)}>In Mã</button>
                                        <PrintQr
                                            Items={selectedItems}
                                            show={showModalEditQr}
                                            setShow={setShowModalEditQr}
                                        />
                                    </div>
                                    <div className="d-flex justify-content-end mt-3 mt-md-0">
                                        <ReactPaginate
                                            breakLabel="..."
                                            nextLabel=">"
                                            onPageChange={handlePageClick}
                                            pageRangeDisplayed={20}
                                            pageCount={pageCount}
                                            previousLabel="<"
                                            renderOnZeroPageCount={null}
                                            marginPagesDisplayed={2}
                                            pageClassName="page-item"
                                            pageLinkClassName="page-link"
                                            previousClassName="page-item"
                                            previousLinkClassName="page-link"
                                            nextClassName="page-item"
                                            nextLinkClassName="page-link"
                                            breakClassName="page-item"
                                            breakLinkClassName="page-link"
                                            containerClassName="pagination"
                                            activeClassName="active"
                                            forcePage={page}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='bg-white p-4'>
                                <Table responsive bordered striped cellSpacing={0}>
                                    <thead>
                                        <tr>
                                            <th style={{ width: '3%' }}></th>
                                            <th style={{ width: '20%' }}>MÃ QR</th>
                                            <th style={{ width: '42%' }}>CHUỖI QR</th>
                                            <th style={{ width: '20%' }}>ĐƠN HÀNG</th>
                                            <th style={{ width: '15%' }}>THÀNH TIỀN</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {qrList.map((item, index) => (
                                            <tr height='30px' key={`qr-${index}`}>
                                                <td>
                                                    {
                                                        item.orderId ? '' :
                                                            (
                                                                <Form.Check
                                                                    inline
                                                                    name={`group${index}`}
                                                                    id={`inline-${index}-1`}
                                                                    checked={selectedItems.includes(item.codeValue)}
                                                                    onChange={(event) => handleSelectOne(event, item.codeValue)}
                                                                />
                                                            )
                                                    }
                                                </td>
                                                <td>{item.codeId}</td>
                                                <td>
                                                    {item.codeValue}
                                                </td>
                                                <td>
                                                    {item.orderId}
                                                </td>
                                                <td className='position-relative'>
                                                    <p className='pe-5'>{Number(item.price).toLocaleString()}</p>
                                                    <Dropdown className='position-absolute top-0 end-0 pt-2 pe-2'>
                                                        <Dropdown.Toggle as={CustomToggle}>
                                                            <i className="fa-solid fa-ellipsis"></i>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item href=""
                                                                onClick={() => {
                                                                    setShowModalQrDetail(true);
                                                                    setQrDetail(item);
                                                                }}
                                                            >
                                                                Xem chi tiết
                                                            </Dropdown.Item>
                                                            <QrDetail
                                                                qrDetail={qrDetail}
                                                                show={showModalQrDetail}
                                                                setShow={setShowModalQrDetail}
                                                            />
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </td>
                                            </tr>
                                        ))}
                                        {rows2}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>

    )
}

export default QrList;