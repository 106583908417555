import Header from "../../layout/Header";
import Sidebar from "../../layout/SideBar";
import Table from 'react-bootstrap/Table';
import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { adminStaffReportTableApi } from "../../apiCaller/services";
import CreateStaff from "./CreateStaff";

const StaffReport = () => {

    const [staffList, setStaffList] = useState([]);
    const accessToken = localStorage.getItem("accessToken");
    const [pageCount, setPageCount] = useState(1);
    const [show, setShow] = useState(false);
    const [month, setMonth] = useState(Number((new Date()).getMonth() + 1));

    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <a
            href=""
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
            style={{ color: '#000000' }}
        >
            {children}
        </a>
    ));

    const fetchStaff = async (page, month) => {
        const staffData = await adminStaffReportTableApi(page, month, accessToken);
        setStaffList(staffData?.data);
        setPageCount(staffData?.totalPage);
    };

    useEffect(() => {
        fetchStaff(1, month);
    }, [])

    const handlePageClick = (event) => {
        fetchStaff(+event.selected + 1, month);
    };

    let rows2;

    if (staffList && staffList.length < 10) {
        rows2 = (
            <>
                {
                    [...Array(10 - staffList.length)].map((_, i) => (
                        <tr style={{ height: '30px' }} key={staffList.length + i}>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    ))
                }
            </>
        );
    };

    return (
        <section>
            <div className="container-fluid w-100 row p-0 m-0" style={{ backgroundColor: '#FAFAFA' }}>
                <Header />
                <div className="row">
                    <Sidebar />
                    <div className="col-lg-10 col-md-8">
                        <div>
                            <div className='mx-5 my-3 row'>
                                <h2 className='col-lg-6 py-4'>Báo cáo nhân viên</h2>
                                <div className='d-md-flex flex-row-reverse col-lg-6 py-4'>
                                    {/* <div className="ms-0 ms-md-4 d-flex justify-content-end">
                                        <button className='btn btn-danger h-100 p-2' style={{ fontSize: '16px' }} onClick={() => setShow(true)}>+&nbsp;&nbsp;Tạo nhân viên</button>
                                        <CreateStaff
                                            show={show}
                                            setShow={setShow}
                                        />
                                    </div> */}
                                    <div className="ms-0 ms-md-4 d-flex justify-content-end">
                                        <select
                                            value={month}
                                            onChange={e => {
                                                setMonth(Number(e.target.value));
                                                fetchStaff(1, Number(e.target.value));
                                            }}
                                            className='p-2 mt-md-0 mt-3'
                                            style={{ fontSize: '16px' }}
                                        >
                                            <option value={"1"}>Tháng 1</option>
                                            <option value={"2"}>Tháng 2</option>
                                            <option value={"3"}>Tháng 3</option>
                                            <option value={"4"}>Tháng 4</option>
                                            <option value={"5"}>Tháng 5</option>
                                            <option value={"6"}>Tháng 6</option>
                                            <option value={"7"}>Tháng 7</option>
                                            <option value={"8"}>Tháng 8</option>
                                            <option value={"9"}>Tháng 9</option>
                                            <option value={"10"}>Tháng 10</option>
                                            <option value={"11"}>Tháng 11</option>
                                            <option value={"12"}>Tháng 12</option>
                                        </select>
                                    </div>
                                    <div className="d-flex justify-content-end mt-3 mt-md-0">
                                        <ReactPaginate
                                            breakLabel="..."
                                            nextLabel=">"
                                            onPageChange={handlePageClick}
                                            pageRangeDisplayed={20}
                                            pageCount={pageCount}
                                            previousLabel="<"
                                            renderOnZeroPageCount={null}
                                            marginPagesDisplayed={2}
                                            pageClassName="page-item"
                                            pageLinkClassName="page-link"
                                            previousClassName="page-item"
                                            previousLinkClassName="page-link"
                                            nextClassName="page-item"
                                            nextLinkClassName="page-link"
                                            breakClassName="page-item"
                                            breakLinkClassName="page-link"
                                            containerClassName="pagination"
                                            activeClassName="active"
                                            className=""
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='' style={{ backgroundColor: '#FFFFFF' }}>
                                <Table responsive bordered cellSpacing={0}>
                                    <thead>
                                        <tr>
                                            <th style={{ width: '15%' }}>MÃ NHÂN VIÊN</th>
                                            <th style={{ width: '15%' }}>TÊN NHÂN VIÊN</th>
                                            <th style={{ width: '15%' }}>TÊN KHO</th>
                                            <th style={{ width: '15%' }}>SỐ ĐƠN NHẬN</th>
                                            <th style={{ width: '15%' }}>SỐ ĐƠN GIAO</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {staffList?.map((item, key) => (
                                            <tr key={`key-${key}`} height='30px'>
                                                <td>{item?.staffId}</td>
                                                <td>{item?.staffName}</td>
                                                <td>{item?.warehouseName}</td>
                                                <td>{item?.totalPickupOrder}</td>
                                                <td>{item?.totalDeliverOrder}</td>
                                            </tr>
                                        ))}
                                        {rows2}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    )
}

export default StaffReport;