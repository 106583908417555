import { Route, Routes } from 'react-router-dom';
import './App.css';
import Login from './components/Login/Login';
import Layout from './components/layout/Layout';
import RequestList from './components/manager/request/RequestList';
import OrderList from './components/manager/order/OrderList';
import QrList from './components/manager/qr/QrList';
import CustomerList from './components/manager/customer/CustomerList';
import StaffList from './components/manager/staff/StaffList';
import ReportManager from './components/manager/reportmanager/report';
import MRevenueReport from './components/manager/reportmanager/MRevenueReport';
import MOrderReport from './components/manager/reportmanager/MOrderReport';
import MStaffReport from './components/manager/reportmanager/MStaffReport';
import SystemSetting from './components/Admin/system/SystemSetting';
import ReportAdmin from './components/Admin/report/Report';
import OrderReport from './components/Admin/orderreport/OrderReport';
import RevenueReport from './components/Admin/revenuereport/RevenueReport';
import CustomerReport from './components/Admin/customerreport/CustomerReport';
import StaffReport from './components/Admin/staffreport/StaffReport';
import WarehouseReport from './components/Admin/warehousereport/WarehouseReport';
import AreaReport from './components/Admin/areareport/AreaReport';
import RequireAuth from './components/context/RequireAuth';
import Test from './components/App';
import Missing from './components/Missing';
import VerifyCode from './components/Login/verifycode';
import ForgotPassword from './components/Login/ForgotPassword';
import ChangePassword from './components/Login/ChangePassword';
import StaffManagement from './components/Admin/staff/StaffManagement';

function App() {

  return (
    <Routes>
      <Route path='/' element={<Layout />}>

        <Route path='/' element={<Login />} />
        <Route path='send-mail' element={<ForgotPassword />} />
        <Route path='verification-code' element={<VerifyCode />} />
        <Route path='change-password' element={<ChangePassword />} />

        <Route element={<RequireAuth allowedRoles={["STAFF", "MANAGER"]} />} >
          <Route path='request' element={<RequestList />} />
          <Route path='order' element={<OrderList />} />
          <Route path='qr' element={<QrList />} />
        </Route>

        <Route path='app' element={<Test />} />

        <Route element={<RequireAuth allowedRoles={["MANAGER", "ADMIN"]} />} >
          <Route path='customer' element={<CustomerList />} />
          <Route path='staff' element={<StaffList />} />
          <Route path='report-manager' element={<ReportManager />} />
          <Route path='manager-revenue-report' element={<MRevenueReport />} />
          <Route path='manager-order-report' element={<MOrderReport />} />
          <Route path='manager-staff-report' element={<MStaffReport />} />
        </Route>

        <Route element={<RequireAuth allowedRoles={["ADMIN"]} />} >
          <Route path='system-setting' element={<SystemSetting />} />
          <Route path='manage-staff' element={<StaffManagement />} />
          <Route path='report-admin' element={<ReportAdmin />} />
          <Route path='order-report' element={<OrderReport />} />
          <Route path='revenue-report' element={<RevenueReport />} />
          <Route path='customer-report' element={<CustomerReport />} />
          <Route path='staff-report' element={<StaffReport />} />
          <Route path='warehouse-report' element={<WarehouseReport />} />
          <Route path='area-report' element={<AreaReport />} />
        </Route>

        {/* catch all */}
        <Route path="*" element={<Missing />} />

      </Route>
    </Routes>
  );
}

export default App;
