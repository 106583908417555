import { useEffect, useState } from "react";
import _ from 'lodash';
import { Button, Modal } from "react-bootstrap";
import { updateRequestNoteApi } from "../../apiCaller/services";
import { toast } from "react-toastify";

const EditRequest = (props) => {

    const { show, setShow, data, fetchRequest, fetchUpdateRequestStt } = props;
    const [note, setNote] = useState('');
    const [errMsg, setErrMsg] = useState('Không để trống ghi chú');
    const accessToken = sessionStorage.getItem("accessToken");

    const fetchUpdateRequestNote = async (data) => {
        const res = await updateRequestNoteApi(data, accessToken);
        if (res) {
            toast.error(res.message);
        }
        fetchUpdateRequestStt(data.recordId, 1);
        fetchRequest(1);
    }

    useEffect(() => {
        if (!_.isEmpty(data)) {

        }
    }, [data]);

    const handleSubmit = () => {
        if (window.confirm(`Bạn có chắc muốn lưu ghi chú không?`)) {
            fetchUpdateRequestNote({
                recordId: Number(data.recordId),
                orderId: Number(data.requestdata.orderId),
                deliverInfoId: Number(data.requestdata.inforId),
                requestType: Number(data.requestTypeId),
                requestStatus: 3,
                note: note
            });
            setShow(false);

        }
    }

    return (
        <>
            <Modal
                show={show}
                backdrop="static"
                onHide={() => setShow(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Thêm ghi chú</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="">
                        <section>
                            <div className="mb-3 input-modal">
                                <label htmlFor="note" className="input-label-modal mb-3">GHI CHÚ</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="note"
                                    name="note"
                                    autoComplete="off"
                                    onChange={(e) => {
                                        setNote(e.target.value);
                                        e.target.value === '' ? setErrMsg('Không để trống ghi chú') : setErrMsg('');
                                    }}
                                    value={note}
                                    aria-describedby="note"
                                    placeholder="Nhập ghi chú"
                                    style={{ padding: '8px 12px' }}
                                    required
                                />
                                <span className="text-danger">{errMsg}</span>
                            </div>
                        </section>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleSubmit}>
                        Lưu ghi chú
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )

}

export default EditRequest;