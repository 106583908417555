import { useEffect, useState } from "react";
import Header from "../../layout/Header"
import Sidebar from "../../layout/SideBar"
import { getManagerDashBoard } from "../../apiCaller/services";


const ReportManager = () => {

    const [revenueTotal, setRevenueTotal] = useState(0);
    const [orderTotal, setOrderTotal] = useState(0);
    const [staffTotal, setStaffTotal] = useState(0);
    const accessToken = localStorage.getItem("accessToken");

    const fetchManagerDashBoard = async () => {
        const DbData = await getManagerDashBoard(accessToken);
        setRevenueTotal(DbData?.revenue);
        setOrderTotal(DbData?.totalorder);
        setStaffTotal(DbData?.staffs);
    }

    useEffect(() => {
        fetchManagerDashBoard();
    }, [])

    return (
        <>
            <div className="container-fluid w-100 row p-0 m-0">
                <Header />
                <div className="row">
                    <Sidebar />
                    <div className="col-lg-10 col-md-8 ps-5" style={{ backgroundColor: '#F5F5F5' }}>
                        <h1 className='my-5'>Báo cáo</h1>
                        <div className="row">
                            <div className="col-sm-6 col-lg-4 p-4">
                                <div className="shadow-sm rounded">
                                    <h1>Doanh thu</h1>
                                    <div className="row mx-1" style={{ backgroundColor: '#FAFAFA' }}>
                                        <i className="fa-solid fa-money-bill-trend-up col-5" style={{ fontSize: '50px' }} ></i>
                                        <div className="col-7">
                                            <p style={{ fontSize: '20px' }}>{revenueTotal.toLocaleString()} VNĐ</p>
                                            <a href="#/manager-revenue-report" style={{ fontSize: '14px' }}>Xem chi tiết</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg-4 p-4">
                                <div className="shadow-sm rounded">
                                    <h1>Đơn hàng</h1>
                                    <div className="row mx-1" style={{ backgroundColor: '#FAFAFA' }}>
                                        <i className="fa-solid fa-truck-fast col-5" style={{ fontSize: '50px' }} ></i>
                                        <div className="col-7">
                                            <p style={{ fontSize: '20px' }}>{orderTotal}</p>
                                            <a href="#/manager-order-report" style={{ fontSize: '14px' }}>Xem chi tiết</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg-4 p-4">
                                <div className="shadow-sm rounded">
                                    <h1>Nhân viên</h1>
                                    <div className="row mx-1" style={{ backgroundColor: '#FAFAFA' }}>
                                        <i className="fa-solid fa-users col-5" style={{ fontSize: '50px' }} ></i>
                                        <div className="col-7">
                                            <p style={{ fontSize: '20px' }}>{staffTotal}</p>
                                            <a href="#/manager-staff-report" style={{ fontSize: '14px' }}>Xem chi tiết</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default ReportManager;