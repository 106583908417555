import React, { useEffect, useRef, useState } from "react";
import Banner from "../layout/Banner";
import { loginApi } from "../apiCaller/services";
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useAuth from "../context/useAuth";

const Login = () => {

    const { auth, setAuth } = useAuth();

    const location = useLocation();
    const from = location.state?.from?.pathname || "/";

    const userRef = useRef();
    const errRef = useRef();

    const navigate = useNavigate();

    const [user, setUser] = useState('');
    const [pwd, setPwd] = useState('');
    const [rememberMe, setRememberMe] = useState(false);
    const [errMsg, setErrMsg] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [loginFail, setLoginFail] = useState('');
    const isLogin = localStorage.getItem("role");

    useEffect(() => {
        userRef.current.focus();
        if (isLogin !== null) {
            if (isLogin === "ADMIN") {
                navigate('report-admin');
            } else {
                navigate('request');
            }
        }
        setUser(localStorage.getItem("username") === null ? '' : localStorage.getItem("username"));
        setPwd(localStorage.getItem("password") === null ? '' : localStorage.getItem("password"));
        setRememberMe(localStorage.getItem("remember") === null ? false : true);
    }, [])

    useEffect(() => {
        setErrMsg('');
    }, [user, pwd]);

    const handleRememberMeChange = (event) => {
        setRememberMe(event.target.checked);
    };

    const handleSubmit = async (e) => {

        e.preventDefault();
        setIsSubmitting(true);
        try {
            const res = (await loginApi(user, pwd));
            const token = res[0].token;
            const payload = res[1].payload;
            setPwd('');
            if (token && payload.role !== 'CUSTOMER' && payload.role !== 'SHIPPER') {
                localStorage.setItem("accessToken", token.accessToken);
                localStorage.setItem("refresh_token", token.refreshToken);
                localStorage.setItem("role", payload.role);
                if (rememberMe) {
                    localStorage.setItem('username', user);
                    localStorage.setItem('password', pwd);
                    localStorage.setItem('remember', rememberMe);
                } else {
                    localStorage.removeItem('username');
                    localStorage.removeItem('password');
                    localStorage.removeItem('remember');
                }
                setAuth({ accessToken: token.accessToken, refreshToken: token.refreshToken, roles: payload.role });

                if (payload.role === 'ADMIN') {
                    navigate('/report-admin');
                } else {
                    navigate('/request');
                }
                setIsSubmitting(false);
            } else {
                toast.error('Wrong Username or Password!');
                setIsSubmitting(false);
            }
        } catch (err) {
            setPwd('');
            if (!err?.response) {
                toast.error(err);
            } else if (err.response?.status === 400) {
                toast.error('Missing Username or Password');
            } else if (err.response?.status === 401) {
                toast.error('Wrong Username or Password!');
            } else {
                toast.error('Login Failed');
            }
            setIsSubmitting(false);
        }
        // console.log('user: ', auth.user)
    }

    return (
        <>
            <section>
                <div className="row container-fluid m-0 p-0 w-100 h-100">
                    <div className="container-fluid col-md-6 col-sm-12 h-100" style={{ marginRight: 0 }}>
                        <div className="w-75 m-auto">
                            <div className="d-flex align-items-center mt-5">
                                <img src="./images/TT-01.png" className="" style={{ width: '50px', height: '50px' }} alt="Logo" />
                                <span className="ms-3" style={{ color: '#FF6827', lineHeight: '44px', fontSize: '36px', fontFamily: 'Inter', fontWeight: 600, wordWrap: 'break-word' }}>Hand2Hand</span>
                            </div>
                            <div className="text-start pt-5">
                                <h1 style={{ color: '#FF6827', lineHeight: '43px', fontSize: '36px', fontFamily: 'Inter', fontWeight: 700, wordWrap: 'break-word' }}>
                                    Hệ thống quản lí giao nhận hàng
                                </h1>
                            </div>
                            <div className="text-start pt-5 my-5">
                                <p style={{ color: '#000000', lineHeight: '22px', fontSize: '18px', fontFamily: 'Inter', fontWeight: 400, wordWrap: 'break-word' }}>
                                    Chào mừng đã trở lại! Vui lòng nhập tài khoản của bạn.
                                </p>
                            </div>
                            <div className="">
                                <div className="" style={{ marginTop: '4rem' }}>
                                    <form action="" method="post">
                                        {/* <p
                                            ref={errRef}
                                            className={errMsg ? "errmsg text-danger" : "offscreen"}
                                            aria-live="assertive"
                                        >
                                            {errMsg}
                                        </p> */}
                                        <p
                                            className={loginFail ? "errmsg text-danger" : "offscreen"}
                                            aria-live="assertive"
                                        >
                                            {loginFail}
                                        </p>
                                        <div className="mb-0 input-block">
                                            <label htmlFor="user" className="input-label">Tên đăng nhập</label>
                                            <input
                                                type="text"
                                                className="form-control fs-3"
                                                id="user"
                                                name="user"
                                                ref={userRef}
                                                autoComplete="off"
                                                onChange={(e) => setUser(e.target.value)}
                                                value={user}
                                                aria-describedby="user"
                                                placeholder="Nhập tên đăng nhập"
                                                required
                                            />
                                        </div>
                                        <div className="mb-3 input-block">
                                            <label htmlFor="password" className="input-label">Mật khẩu</label>
                                            <input
                                                type="password"
                                                className="form-control fs-3"
                                                id="password"
                                                name="password"
                                                onChange={(e) => setPwd(e.target.value)}
                                                value={pwd}
                                                placeholder="Nhập mật khẩu"
                                                required
                                            />
                                        </div>
                                        <div className="my-5 form-check row" style={{ display: 'flex' }}>
                                            <div className="col fs-3 ms-3">
                                                <input
                                                    type="checkbox"
                                                    id="checkbox"
                                                    name="checkbox"
                                                    checked={rememberMe}
                                                    onChange={handleRememberMeChange}
                                                    className="form-check-input"
                                                />
                                                <label htmlFor="checkbox" className="form-check-label">Nhớ tài khoản</label>
                                            </div>
                                            <div className="text-end col fs-3">
                                                <a href="#/send-mail" className="" style={{ color: '#000000' }}>Quên mật khẩu?</a>
                                            </div>
                                        </div>
                                        <button type="submit" onClick={handleSubmit} className="btn fs-3 mb-5" disabled={isSubmitting} style={{ width: '100%', backgroundColor: '#FF6827', borderRadius: '100px', color: '#ffffff' }}>
                                            {isSubmitting ? 'Đang đăng nhập...' : 'Đăng nhập'}
                                        </button>
                                    </form>
                                </div>
                                <div className="row text-center my-5 fs-3">
                                    <div className="col-4 d-flex flex-row-reverse">
                                        <a href="#" className="text-danger">@H2H</a>
                                    </div>
                                    <div className="col-4 fs-3">
                                        <a href="#" className="text-danger">Contact</a>
                                    </div>
                                    <div className="col-4 d-flex flex-row fs-3">
                                        <a href="#" className="text-danger">Privacy & Terms</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 banner-hide m-0 p-0">
                        <Banner />
                    </div>
                </div>
            </section>
        </>
    );
}

export default Login;