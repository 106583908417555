import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { createRequestApi, getAccountDetailApi } from "../../apiCaller/services";
import { toast } from "react-toastify";

const CreateRequest = (props) => {

    const { show, setShow, warehouseList, shipperList } = props;
    const [whFrom, setWhFrom] = useState("");
    const [note, setNote] = useState('');
    const [selectedWarehouse, setSelectedWarehouse] = useState(0);
    const [selectedShipper, setSelectedShipper] = useState(0);

    const accessToken = localStorage.getItem("accessToken");

    const fetchStaffInfo = async () => {
        try {
            const staffData = await getAccountDetailApi(accessToken);
            setWhFrom(staffData?.data?.staff?.warehouseName);
        } catch (err) {

        }
    }

    useEffect(() => {
        fetchStaffInfo();
    },[])

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (window.confirm(`Bạn có chắc muốn tạo yêu cầu trung chuyển?`)) {
            try {
                const res = await createRequestApi(selectedWarehouse, selectedShipper, note, accessToken);
                if (res.status === 200) {
                    props.fetchRequest(1);
                    setShow(false);
                    toast.success(`Tạo yêu cầu trung chuyển thành công!`)
                } else {
                    toast.error(`Đang không có đơn hàng nào cần trung chuyển!`);
                }
            } catch (err) {
                toast.error(`Error: ${err.response}`);
            }
        }
        else {

        }

    };

    return (
        <>
            <Modal
                show={show}
                backdrop="static"
                onHide={() => setShow(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Tạo yêu cầu</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="">
                        <section>
                            <div className="mb-3 edit-modal">
                                <label htmlFor="whFrom" className="edit-label-modal mb-3">KHO ĐI <span style={{ color: 'red' }}>*</span></label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="whFrom"
                                    name="whFrom"
                                    autoComplete="off"
                                    onChange={(e) => setWhFrom(e.target.value)}
                                    value={whFrom}
                                    aria-describedby="whFrom"
                                    style={{ padding: '8px 12px' }}
                                    disabled
                                />
                            </div>
                            <div className="mb-3 edit-modal">
                                <label htmlFor="whTo" className="edit-label-modal mb-3">KHO ĐẾN <span style={{ color: 'red' }}>*</span></label>
                                <select
                                    value={selectedWarehouse}
                                    onChange={(e) => {
                                        const newSelectedWarehouse = e.target.value;
                                        setSelectedWarehouse(newSelectedWarehouse);
                                    }}
                                    className="p-3"
                                >
                                    <option value={0}>Chọn kho đến</option>
                                    {warehouseList.map((warehouse, index) => (
                                        <option key={`warehouse-${index}`} value={warehouse?.wh_warehouse_id}>{warehouse?.warehouse_name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="mb-3 edit-modal">
                                <label htmlFor="shipper" className="edit-label-modal mb-3">NHÂN VIÊN VẬN CHUYỂN <span style={{ color: 'red' }}>*</span></label>
                                <select
                                    value={selectedShipper}
                                    onChange={(e) => {
                                        const newSelectedShipper = e.target.value;
                                        setSelectedShipper(newSelectedShipper);
                                    }}
                                    className="p-3"
                                >
                                    <option value={0}>Chọn nhân viên</option>
                                    {shipperList.map((shipper, index) => (
                                        <option key={`shipper-${index}`} value={shipper.staffId}>{shipper.fullname}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="mb-3 edit-modal">
                                <label htmlFor="note" className="edit-label-modal mb-3">GHI CHÚ</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="note"
                                    name="note"
                                    autoComplete="off"
                                    onChange={(e) => setNote(e.target.value)}
                                    value={note}
                                    aria-describedby="note"
                                    placeholder="Nhập ghi chú"
                                    style={{ padding: '8px 12px' }}
                                    required
                                />
                            </div>
                        </section>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleSubmit}>
                        Tạo yêu cầu
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )

}

export default CreateRequest;