import React from "react";
import WarehouseList from "../../manager/warehouse/WarehouseList";

const SystemSetting = () => {

    return (
        <>
            <WarehouseList />
        </>
    );

}

export default SystemSetting;