import { useEffect, useState } from "react";
import _ from "lodash";
import { Button, Modal } from "react-bootstrap";

const RequestDetail = (props) => {

    const { show, setShow, requestDetail } = props;
    const [requestId, setRequestId] = useState('');
    const [orderId, setOrderId] = useState('');
    const [whFrom, setWhFrom] = useState('');
    const [whTo, setWhTo] = useState('');
    const [shipper, setShipper] = useState('');
    const [createdDate, setCreatedDate] = useState('');
    const [requestType, setRequestType] = useState(0);
    const [requestStt, setRequestStt] = useState(0);

    useEffect(() => {
        if (!_.isEmpty(requestDetail)) {
            setRequestId(requestDetail.recordId);
            setOrderId(requestDetail.requestdata.orderId);
            setWhFrom(requestDetail.transitdata.warehoueFrom);
            setWhTo(requestDetail.transitdata.warehoueTo);
            setShipper(requestDetail.transitdata.staff);
            setCreatedDate(requestDetail.date_created);
            setRequestType(requestDetail.requestTypeId);
            setRequestStt(requestDetail.requesStatusId);
        }
    }, [requestDetail]);

    const getRequestDescription = (type, orderId) => {
        switch (type) {
            case 1:
                return `Thay đổi địa chỉ cho đơn hàng ${orderId}`;
            case 2:
                return `Huỷ đơn hàng ${orderId}`;
            default:
                return `Trung chuyển từ kho A đến kho ${orderId}`;
        }
    }

    const getStatusText = (status) => {
        switch (status) {
            case 1:
                return 'Đang xử lý';
            case 2:
                return 'Đã duyệt';
            default:
                return 'Từ chối';
        }
    }

    return (
        <>
            <Modal
                show={show}
                backdrop="static"
                onHide={() => setShow(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Chi tiết yêu cầu</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        requestDetail.requestTypeId === 3 ? (
                            <div className="">
                                <section>
                                    <div className="mb-3 input-modal">
                                        <label htmlFor="whFrom" className="input-label-modal mb-3">KHO ĐI</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="whFrom"
                                            name="whFrom"
                                            autoComplete="off"
                                            onChange={(e) => setRequestId(e.target.value)}
                                            value={whFrom}
                                            aria-describedby="whFrom"
                                            style={{ padding: '8px 12px' }}
                                            disabled
                                        />
                                    </div>
                                    <div className="mb-3 input-modal">
                                        <label htmlFor="whTo" className="input-label-modal mb-3">KHO ĐẾN</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="whTo"
                                            name="whTo"
                                            autoComplete="off"
                                            onChange={(e) => setOrderId(e.target.value)}
                                            value={whTo}
                                            aria-describedby="whTo"
                                            style={{ padding: '8px 12px' }}
                                            disabled
                                        />
                                    </div>
                                    <div className="mb-3 input-modal">
                                        <label htmlFor="shipper" className="input-label-modal mb-3">NHÂN VIÊN VẬN CHUYỂN</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="shipper"
                                            name="shipper"
                                            autoComplete="off"
                                            onChange={(e) => setOrderId(e.target.value)}
                                            value={shipper}
                                            aria-describedby="shipper"
                                            style={{ padding: '8px 12px' }}
                                            disabled
                                        />
                                    </div>
                                    <div className="mb-3 input-modal">
                                        <label htmlFor="createdDate" className="input-label-modal mb-3">THỜI GIAN TẠO</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="createdDate"
                                            name="createdDate"
                                            autoComplete="off"
                                            onChange={(e) => setOrderId(e.target.value)}
                                            value={createdDate}
                                            aria-describedby="createdDate"
                                            style={{ padding: '8px 12px' }}
                                            disabled
                                        />
                                    </div>
                                    <div className="mb-3 input-modal">
                                        <label htmlFor="requestStt" className="input-label-modal mb-3">TÌNH TRẠNG</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="requestStt"
                                            name="requestStt"
                                            autoComplete="off"
                                            onChange={(e) => setOrderId(e.target.value)}
                                            value={getStatusText(requestStt)}
                                            aria-describedby="requestStt"
                                            style={{ padding: '8px 12px' }}
                                            disabled
                                        />
                                    </div>
                                </section>
                            </div>
                        ) : (
                            <div className="">
                                <section>
                                    <div className="mb-3 input-modal">
                                        <label htmlFor="requestId" className="input-label-modal mb-3">MÃ YÊU CẦU</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="requestId"
                                            name="requestId"
                                            autoComplete="off"
                                            onChange={(e) => setRequestId(e.target.value)}
                                            value={requestId}
                                            aria-describedby="requestId"
                                            style={{ padding: '8px 12px' }}
                                            disabled
                                        />
                                    </div>
                                    <div className="mb-3 input-modal">
                                        <label htmlFor="orderId" className="input-label-modal mb-3">MÃ ĐƠN HÀNG</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="orderId"
                                            name="orderId"
                                            autoComplete="off"
                                            onChange={(e) => setOrderId(e.target.value)}
                                            value={orderId}
                                            aria-describedby="orderId"
                                            style={{ padding: '8px 12px' }}
                                            disabled
                                        />
                                    </div>
                                    <div className="mb-3 input-modal">
                                        <label htmlFor="shipper" className="input-label-modal mb-3">NHÂN VIÊN VẬN CHUYỂN</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="shipper"
                                            name="shipper"
                                            autoComplete="off"
                                            onChange={(e) => setOrderId(e.target.value)}
                                            value={shipper}
                                            aria-describedby="shipper"
                                            style={{ padding: '8px 12px' }}
                                            disabled
                                        />
                                    </div>
                                    <div className="mb-3 input-modal">
                                        <label htmlFor="requestType" className="input-label-modal mb-3">LOẠI YÊU CẦU</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="requestType"
                                            name="requestType"
                                            autoComplete="off"
                                            onChange={(e) => setOrderId(e.target.value)}
                                            value={getRequestDescription(requestType, orderId)}
                                            aria-describedby="requestType"
                                            style={{ padding: '8px 12px' }}
                                            disabled
                                        />
                                    </div>
                                    <div className="mb-3 input-modal">
                                        <label htmlFor="requestStt" className="input-label-modal mb-3">TÌNH TRẠNG</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="requestStt"
                                            name="requestStt"
                                            autoComplete="off"
                                            onChange={(e) => setOrderId(e.target.value)}
                                            value={getStatusText(requestStt)}
                                            aria-describedby="requestStt"
                                            style={{ padding: '8px 12px' }}
                                            disabled
                                        />
                                    </div>
                                </section>
                            </div>
                        )
                    }
                </Modal.Body>
                {
                    requestDetail.requestTypeId === 3 ? (
                        <Modal.Footer>
                            <Button variant="primary" onClick={() => setShow(false)}>
                                Duyệt yêu cầu
                            </Button>
                        </Modal.Footer>
                    ) : (
                        <Modal.Footer>
                            <Button variant="primary" onClick={() => setShow(false)}>
                                Từ chối
                            </Button>
                            <Button variant="primary" onClick={() => setShow(false)}>
                                Chấp nhận
                            </Button>
                        </Modal.Footer>
                    )
                }
            </Modal>
        </>
    )

}

export default RequestDetail;