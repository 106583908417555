import _ from "lodash";
import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";

const CustomerDetail = (props) => {

    const { show, setShow, customerDetail } = props;
    const [cusId, setCusId] = useState("");
    const [fullname, setFullname] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [address, setAddress] = useState("");
    const [status, setStatus] = useState("");

    useEffect(() => {

        if (!_.isEmpty(customerDetail)) {
            setCusId(customerDetail?.customerid);
            setFullname(customerDetail?.c_fullname);
            setEmail(customerDetail?.c_email);
            setPhone(customerDetail?.c_phone);
            setAddress(`${customerDetail?.house}, ${customerDetail?.wardname}, ${customerDetail?.districtname}, ${customerDetail?.cityname}`);
            setStatus(customerDetail?.statusname);
        }
    }, [customerDetail]);

    return (
        <>
            <Modal show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Chi tiết khách hàng</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="">
                        <section>
                            <div className="mb-3 input-modal">
                                <label htmlFor="cusId" className="input-label-modal mb-3">MÃ KHÁCH HÀNG</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="cusId"
                                    name="cusId"
                                    autoComplete="off"
                                    onChange={(e) => setCusId(e.target.value)}
                                    value={cusId}
                                    aria-describedby="cusId"
                                    style={{ padding: '8px 12px' }}
                                    disabled
                                />
                            </div>
                            <div className="mb-3 input-modal">
                                <label htmlFor="name" className="input-label-modal mb-3">TÊN KHÁCH HÀNG</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="name"
                                    name="name"
                                    autoComplete="off"
                                    onChange={(e) => setFullname(e.target.value)}
                                    value={fullname}
                                    aria-describedby="name"
                                    style={{ padding: '8px 12px' }}
                                    disabled
                                />
                            </div>
                            <div className="mb-3 input-modal">
                                <label htmlFor="email" className="input-label-modal mb-3">EMAIL</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    id="email"
                                    name="email"
                                    autoComplete="off"
                                    onChange={(e) => setEmail(e.target.value)}
                                    value={email}
                                    aria-describedby="email"
                                    style={{ padding: '8px 12px' }}
                                    disabled
                                />
                            </div>
                            <div className="mb-3 input-modal">
                                <label htmlFor="phone" className="input-label-modal mb-3">SỐ ĐIỆN THOẠI</label>
                                <input
                                    type="tel"
                                    className="form-control"
                                    id="phone"
                                    name="phone"
                                    autoComplete="off"
                                    onChange={(e) => setPhone(e.target.value)}
                                    value={phone}
                                    aria-describedby="phone"
                                    style={{ padding: '8px 12px' }}
                                    disabled
                                />
                            </div>
                            <div className="mb-3 input-modal">
                                <label htmlFor="address" className="input-label-modal mb-3">ĐỊA CHỈ</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="address"
                                    name="address"
                                    autoComplete="off"
                                    onChange={(e) => setAddress(e.target.value)}
                                    value={address}
                                    aria-describedby="address"
                                    style={{ padding: '8px 12px' }}
                                    disabled
                                />
                            </div>
                            <div className="mb-3 input-modal">
                                <label htmlFor="status" className="input-label-modal mb-3">TÌNH TRẠNG</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="status"
                                    name="status"
                                    autoComplete="off"
                                    onChange={(e) => setStatus(e.target.value)}
                                    value={status}
                                    aria-describedby="status"
                                    style={{ padding: '8px 12px' }}
                                    disabled
                                />
                            </div>
                        </section>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => setShow(false)}>
                        Đóng
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )

}

export default CustomerDetail;