import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { getCitiesApi, getDistrictsByCityApi, getWardsByDistrictApi, updateProfileApi } from "../../apiCaller/services";
import { toast } from "react-toastify";

const EditProfile = (props) => {

    const { show, setShow, showProfile, setShowProfile, user, fetchUser } = props;
    const [userName, setUserName] = useState(user?.fullname);
    const [email, setEmail] = useState(user?.email);
    const [house, setHouse] = useState(user?.address?.house ? user?.address?.house : "");
    const [ward, setWard] = useState(user?.address?.wardId ? user?.address?.wardId : 1);
    const [district, setDistrict] = useState(user?.address?.districtId ? user?.address?.districtId : 1);
    const [city, setCity] = useState(user?.address?.cityId ? user?.address?.cityId : 1);
    const [phone, setPhone] = useState(user?.phone);
    const wh = user?.warehouseName;
    const [errFullname, setErrFullname] = useState("");
    const [errEmail, setErrEmail] = useState("");
    const [errPhone, setErrPhone] = useState("");
    const [cities, setCities] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [wards, setWards] = useState([]);
    const accessToken = localStorage.getItem("accessToken");

    const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    const isValidPhone = /^0[0-9]{9}$/g;

    const validatePhone = (value) => {
        if (value.match(isValidPhone)) {
            return true;
        } else {
            return false
        }
    }

    const validateEmail = (value) => {
        if (value.match(isValidEmail)) {
            return true;
        } else {
            return false
        }
    }

    const handleClose = async () => {
        const errors = {};

        if (userName === "") errors.username = "Bạn chưa điền username";
        if (email === "") errors.email = "Bạn chưa điền email";
        else if (!validateEmail(email)) errors.email = "Định dạng email chưa đúng!";
        if (phone === "") errors.phone = "Bạn chưa điền số điện thoại";
        else if (!validatePhone(phone)) errors.phone = "Định dạng số điện thoại chưa đúng!";

        if (Object.keys(errors).length > 0) {
            setErrFullname(errors.fullname || "");
            setErrEmail(errors.email || "");
            setErrPhone(errors.phone || "");
        } else {
            if (window.confirm("Bạn có muốn lưu thay đổi?")) {
                try {
                    const data = {
                        fullname: userName,
                        email: email,
                        phone: phone,
                        house: house,
                        cityId: city,
                        districtId: Number(district),
                        wardId: Number(ward)
                    }
                    console.log(data);
                    const res = await updateProfileApi(data, accessToken);
                    if (res) {
                        toast.success("Cập nhật thông tin thành công!");
                        fetchUser();
                    } else {
                        toast.error("")
                    }
                } catch (err) {
                    toast.error("Cập nhật lỗi", err?.response)
                }
            } else {

            }
        }
    }

    const fetchCities = async () => {
        const res = await getCitiesApi();
        setCities(res);
    }

    const fetchDistricts = async (cityId) => {
        const res = await getDistrictsByCityApi(cityId);
        // setDistrict(res.wardId);
        setDistricts(res);
    }

    const fetchWards = async (districtID) => {
        const res = await getWardsByDistrictApi(districtID);
        // setWard(res[0].wardId);
        setWards(res);
    }

    useEffect(() => {
        fetchCities();
        fetchDistricts(city);
        fetchWards(district);
    }, []);

    return (
        <>
            <Modal
                show={show}
                backdrop="static"
                onHide={() => {
                    setShow(false);
                    window.location.reload();
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Thông tin</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mt-4 pt-2 mb-4 pb-2">
                        <div className="rounded shadow mt-4">
                            <div className="p-4">
                                <div className="row" style={{ fontSize: '14px' }}>
                                    <div className="col-md-12">
                                        <div className="mb-3">
                                            <label htmlFor="name" className="form-label">Tên</label>
                                            <input
                                                name="name"
                                                id="name"
                                                type="text"
                                                className="form-control"
                                                value={userName}
                                                onChange={(e) => setUserName(e.target.value)}
                                                style={{ fontSize: '14px' }}
                                            />
                                            <p style={{ color: 'red' }}>{errFullname}</p>
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="mb-3">
                                            <label htmlFor="email" className="form-label">Email</label>
                                            <input
                                                name="email"
                                                id="email"
                                                type="email"
                                                className="form-control"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                style={{ fontSize: '14px' }}
                                            />
                                            <p style={{ color: 'red' }}>{errEmail}</p>
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="mb-3">
                                            <label htmlFor="city" className="form-label">Tỉnh/Thành phố</label>
                                            <select
                                                value={city}
                                                onChange={(e) => {
                                                    setCity(e.target.value);
                                                    fetchDistricts(e.target.value);
                                                }}
                                                className="p-2 w-100"
                                            >
                                                {cities.map((item, index) => (
                                                    <option key={`city-${index}`} value={item.cityId}>{item.cityName}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="mb-3">
                                            <label htmlFor="district" className="form-label">Quận/Huyện</label>
                                            <select
                                                value={district}
                                                onChange={(e) => {
                                                    setDistrict(e.target.value);
                                                    fetchWards(e.target.value);
                                                }}
                                                className="p-2 w-100"
                                            >
                                                {districts.map((item, index) => (
                                                    <option key={`district-${index}`} value={item.districtId}>{item.districtName}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="mb-3">
                                            <label htmlFor="ward" className="form-label">Phường/Xã</label>
                                            <select
                                                value={ward}
                                                onChange={(e) => {
                                                    setWard(e.target.value);
                                                }}
                                                className="p-2 w-100"
                                            >
                                                {wards.map((item, index) => (
                                                    <option key={`ward-${index}`} value={item.wardId}>{item.wardName}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="mb-3">
                                            <label htmlFor="address" className="form-label">Địa chỉ</label>
                                            <input
                                                name="address"
                                                id="address"
                                                type="text"
                                                className="form-control"
                                                value={house}
                                                onChange={(e) => setHouse(e.target.value)}
                                                style={{ fontSize: '14px' }}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="mb-3">
                                            <label htmlFor="phone" className="form-label">Số điện thoại</label>
                                            <input
                                                name="phone"
                                                id="phone"
                                                type="tel"
                                                className="form-control"
                                                value={phone}
                                                onChange={(e) => setPhone(e.target.value)}
                                                style={{ fontSize: '14px' }}
                                            />
                                            <p style={{ color: 'red' }}>{errPhone}</p>
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="mb-3">
                                            <label htmlFor="warehouse" className="form-label">Kho</label>
                                            <input
                                                name="warehouse"
                                                id="warehouse"
                                                type="text"
                                                className="form-control"
                                                value={wh}
                                                style={{ fontSize: '14px' }}
                                                disabled
                                            />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {/* <Button variant="light" onClick={handleShowProfile}>
                        Quay lại
                    </Button> */}
                    <Button variant="primary" onClick={handleClose}>
                        Lưu thay đổi
                    </Button>
                </Modal.Footer>
            </Modal>
        </>

    )
}

export default EditProfile;