import Table from 'react-bootstrap/Table';
import Header from '../../layout/Header';
import Sidebar from '../../layout/SideBar';
import Dropdown from 'react-bootstrap/Dropdown';
import React, { useEffect, useState } from 'react';
import RequestDetail from './RequestDetail';
import EditRequest from './EditRequest';
import CreateRequest from './CreateRequest';
import ReactPaginate from 'react-paginate';

// Be sure to include styles at some point, probably during your bootstraping
import '@trendmicro/react-paginations/dist/react-paginations.css';
import { getRequestListApi, getShipperListApi, getWarehouseListStaffApi, updateRequestSttApi } from '../../apiCaller/services';


const RequestList = () => {

    const [showModalCreateRequest, setShowModalCreateRequest] = useState(false);
    const [showModalEditRequest, setShowModalEditRequest] = useState(false);
    const [showModalRequestDetail, setShowModalRequestDetail] = useState(false);
    const accessToken = localStorage.getItem("accessToken");
    const [requestList, setRequestList] = useState([]);
    const [requestDetail, setRequestDetail] = useState({});
    const [pageCount, setPageCount] = useState(1);
    const [selectedRequestStt, setSelectedRequestStt] = useState(new Array(0).fill(0));
    const [warehouseList, setWarehouseList] = useState([]);
    const [shipperList, setShipperList] = useState([]);
    let dataUpdate;

    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <a
            href=""
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
            style={{ color: '#000000' }}
        >
            {children}
        </a>
    ));

    const fetchRequest = async (page) => {
        const requestData = await getRequestListApi(page, accessToken);
        setRequestList(requestData?.data);
        setPageCount(requestData?.pages?.totalPages);
    };

    const fetchUpdateRequestStt = async (recordId, requestStatus) => {
        try {
            const res = await updateRequestSttApi(recordId, requestStatus, accessToken);
            fetchRequest(1);
        } catch (err) {
            console.log("Error: ", err.response);
        }
    }

    const UpdateRequestStt = (index, item, requestStatus) => {
        if (window.confirm(`Bạn có chắc muốn ${Number(requestStatus) === 2 ? 'Duyệt' : 'Từ chối'} yêu cầu này không?`)) {
            if (Number(requestStatus) === 3) {
                dataUpdate = {
                    recordId: item?.recordId,
                    orderId: item?.requestdata?.orderId,
                    deliverInfoId: item?.requestdata?.inforId,
                    requestType: item?.requestTypeId,
                    requestStatus: 3,
                    note: ""
                };
                setShowModalEditRequest(true);
                const newSelectedRequestStt = [...selectedRequestStt];
                newSelectedRequestStt[index] = 1;
                setSelectedRequestStt(newSelectedRequestStt);
            } else {
                fetchUpdateRequestStt(item?.recordId, requestStatus)
            }
        } else {
            const newSelectedRequestStt = [...selectedRequestStt];
            newSelectedRequestStt[index] = 1;
            setSelectedRequestStt(newSelectedRequestStt);
        }
    };

    const fetchShipper = async () => {
        const shipperData = await getShipperListApi(accessToken);
        setShipperList(shipperData);
    };

    const fetchWarehouseList = async () => {
        const warehouseData = await getWarehouseListStaffApi(accessToken);
        setWarehouseList(warehouseData);
    };

    useEffect(() => {
        fetchRequest(1);
        fetchShipper();
        fetchWarehouseList();
        const interval = setInterval(() => {
            window.location.reload();
        }, 300000);

        return () => clearInterval(interval);
    }, [])

    const handlePageClick = (event) => {
        fetchRequest(+event.selected + 1);
    };

    let rows2;

    if (requestList && requestList.length < 10) {
        rows2 = (
            <>
                {
                    [...Array(10 - requestList.length)].map((_, i) => (
                        <tr style={{ height: '30px' }} key={requestList.length + i}>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    ))
                }
            </>
        );
    };

    const getRequestDescription = (type, orderId, warehoueFrom, whTo) => {
        switch (type) {
            case 1:
                return <>{`Thay đổi địa chỉ cho đơn hàng ${orderId}`}</>;
            case 2:
                return <>{`Huỷ đơn hàng ${orderId}`}</>;
            default:
                return <>{`Trung chuyển từ kho ${warehoueFrom} đến kho ${whTo}`}</>;
        }
    }

    const getStatusColor = (status) => {
        switch (status) {
            case 1:
                return 'blue';
            case 2:
                return 'green';
            default:
                return 'red';
        }
    }

    const getStatusText = (status) => {
        switch (status) {
            case 1:
                return 'Đang xử lý';
            case 2:
                return 'Đã duyệt';
            default:
                return 'Từ chối';
        }
    }

    const convertUtcToGmt7Plus = (value) => {
        const date = new Date(value);
        date.setTime(date.getTime() + 7 * 60 * 60 * 1000);
        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        };
        const gmtPlus7DateString = date.toLocaleString('en-US', options).replace(/\b(Monday|Tuesday|Wednesday|Thursday|Friday|Saturday|Sunday)\b/g, function (match) {
            const dayOfWeek = match.toLowerCase();
            return dayOfWeek.charAt(0).toUpperCase() + dayOfWeek.slice(1);
        });

        return gmtPlus7DateString;
    }

    return (
        <section>
            <div className="container-fluid w-100 row p-0 m-0" style={{ backgroundColor: '#F5F5F5' }}>
                <Header />
                <div className="row">
                    <Sidebar />
                    <div className='col-lg-10 col-md-8 col-12 ps-5' style={{ fontSize: '14px', lineHeight: '20px' }}>
                        <div className='my-5'>
                            <h1>Quản lý yêu cầu</h1>
                        </div>
                        <div className='w-100 '>
                            <div className='d-flex justify-content-between row align-items-center bg-white list-header p-4'>
                                <h2 className='col-lg-6 col-12'>Danh sách yêu cầu</h2>
                                <div className='d-md-flex flex-row-reverse col-lg-6 col-12 py-4'>
                                    <div className="ms-0 ms-md-4 d-flex justify-content-end">
                                        <button className='btn btn-danger h-100 p-2' style={{ fontSize: '16px' }} onClick={() => setShowModalCreateRequest(true)}>+&nbsp;&nbsp;Tạo yêu cầu</button>
                                        <CreateRequest
                                            show={showModalCreateRequest}
                                            setShow={setShowModalCreateRequest}
                                            fetchRequest={fetchRequest}
                                            warehouseList={warehouseList}
                                            shipperList={shipperList}
                                        />
                                    </div>
                                    <div className="d-flex justify-content-end mt-3 mt-md-0">
                                        <ReactPaginate
                                            breakLabel="..."
                                            nextLabel=">"
                                            onPageChange={handlePageClick}
                                            pageRangeDisplayed={5}
                                            pageCount={pageCount}
                                            previousLabel="<"
                                            renderOnZeroPageCount={null}
                                            marginPagesDisplayed={2}
                                            pageClassName="page-item"
                                            pageLinkClassName="page-link"
                                            previousClassName="page-item"
                                            previousLinkClassName="page-link"
                                            nextClassName="page-item"
                                            nextLinkClassName="page-link"
                                            breakClassName="page-item"
                                            breakLinkClassName="page-link"
                                            containerClassName="pagination"
                                            activeClassName="active"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='bg-white p-4'>
                                <Table responsive bordered striped cellSpacing={0}>
                                    <thead>
                                        <tr>
                                            <th style={{ width: '10%' }}>CODE</th>
                                            <th style={{ width: '30%' }}>LOẠI YÊU CẦU</th>
                                            <th style={{ width: '20%' }}>THỜI GIAN TẠO</th>
                                            <th style={{ width: '10%' }}>TÌNH TRẠNG</th>
                                            <th style={{ width: '35%' }}>GHI CHÚ</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {requestList.map((item, index) => (
                                            <tr key={`request-${index}`} id={item.type}>
                                                <td>{item?.recordId}</td>
                                                <td>
                                                    {getRequestDescription(item?.requestTypeId, item?.requestdata?.orderId, item?.transitdata?.warehoueFrom, item?.transitdata?.warehoueTo)}
                                                </td>
                                                <td>{convertUtcToGmt7Plus(item?.date_created)}</td>
                                                <td style={{ color: getStatusColor(item?.requesStatusId) }} id={item?.type}>
                                                    {item?.requesStatusId === 1 ?
                                                        (
                                                            <>
                                                                <select
                                                                    className=''
                                                                    value={selectedRequestStt[index]}
                                                                    onChange={(e) => {
                                                                        if (e.target.value !== 1) {
                                                                            setRequestDetail(item);
                                                                            const newSelectedRequestStt = [...selectedRequestStt];
                                                                            newSelectedRequestStt[index] = e.target.value;
                                                                            setSelectedRequestStt(newSelectedRequestStt);
                                                                            UpdateRequestStt(index, item, e.target.value);
                                                                        }
                                                                    }}
                                                                >
                                                                    <option value={1}>Đang xử lý</option>
                                                                    <option value={2}>Duyệt</option>
                                                                    <option value={3}>Từ chối</option>
                                                                </select>
                                                                <EditRequest
                                                                    show={showModalEditRequest}
                                                                    setShow={setShowModalEditRequest}
                                                                    requestDetail={requestDetail}
                                                                    data={requestDetail}
                                                                    fetchRequest={fetchRequest}
                                                                    fetchUpdateRequestStt={fetchUpdateRequestStt}
                                                                />
                                                            </>
                                                        ) :
                                                        (getStatusText(item?.requesStatusId))
                                                    }
                                                </td>
                                                <td className='position-relative' id={item?.type}>
                                                    <p className='pe-5'>{item?.note}</p>
                                                    <Dropdown className='position-absolute top-0 end-0 pt-2 pe-2'>
                                                        <Dropdown.Toggle as={CustomToggle}>
                                                            <i className="fa-solid fa-ellipsis"></i>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item
                                                                href=""
                                                                onClick={() => {
                                                                    setRequestDetail(item);
                                                                    setShowModalRequestDetail(true);
                                                                }}
                                                            >
                                                                Xem chi tiết
                                                            </Dropdown.Item>
                                                            <RequestDetail
                                                                show={showModalRequestDetail}
                                                                setShow={setShowModalRequestDetail}
                                                                requestDetail={requestDetail}
                                                            />
                                                            {/* <Dropdown.Item href="" onClick={() => setShowModalEditRequest(true)}>Chỉnh sửa</Dropdown.Item> */}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </td>
                                            </tr>
                                        ))}
                                        {rows2}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>

    )
}

export default RequestList;