import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { resetPasswordApi, updateProfileApi } from "../../apiCaller/services";
import { toast } from "react-toastify";

const ResetPassword = (props) => {

    const { show, setShow } = props;
    const [oldPwd, setOldPwd] = useState('');
    const [newPwd, setNewPwd] = useState('');
    const [reNewPwd, setReNewPwd] = useState('');
    const [errPwd, setErrPwd] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const accessToken = localStorage.getItem("accessToken");

    const isValidPassword = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,32}$/g;

    const validatePassword = (password) => {
        return password.match(isValidPassword);
    };

    const handleReset = async () => {
        if (window.confirm("Bạn có muốn lưu thay đổi?")) {
            if (newPwd === reNewPwd) {
                try {
                    const data = {
                        oldpassword: oldPwd,
                        newpassword: newPwd,
                    }
                    const res = await resetPasswordApi(data, accessToken);
                    if (res && res?.status === 200) {
                        toast.success("Cập nhật thông tin thành công!");
                        setShow(false);
                    } else {
                        toast.error("Mật khẩu cũ không đúng!");
                    }
                } catch (err) {
    
                }
            }
        } else {

        }
    }

    useEffect(() => {
    }, []);

    return (
        <>
            <Modal
                show={show}
                backdrop="static"
                onHide={() => {
                    setShow(false);
                }}>
                <Modal.Header closeButton>
                    <Modal.Title>Đổi mật khẩu</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mt-4 pt-2 mb-4 pb-2">
                        <div className="rounded shadow mt-4">
                            <div className="p-4">
                                <div className="row" style={{ fontSize: '14px' }}>
                                    <div className="col-md-12">
                                        <div className="mb-3">
                                            <label htmlFor="oldpassword" className="form-label">Mật khẩu cũ</label>
                                            <input
                                                name="oldpassword"
                                                id="oldpassword"
                                                type="password"
                                                className="form-control"
                                                value={oldPwd}
                                                onChange={(e) => setOldPwd(e.target.value)}
                                                style={{ fontSize: '14px' }}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="mb-3">
                                            <label htmlFor="newpassword" className="form-label">Mật khẩu mới</label>
                                            <p className="text-danger" style={{ fontSize: '16px' }}>{errPwd}</p>
                                            <input
                                                name="newpassword"
                                                id="newpassword"
                                                type="password"
                                                className="form-control"
                                                value={newPwd}
                                                onChange={(e) => {
                                                    setNewPwd(e.target.value);
                                                    validatePassword(e.target.value)?setErrPwd(''):setErrPwd("Mật khẩu sai định dạng!");
                                                }}
                                                style={{ fontSize: '14px' }}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="mb-3">
                                            <label htmlFor="renewpassword" className="form-label">Nhập lại mật khẩu mới</label>
                                            <p className="text-danger" style={{ fontSize: '16px' }}>{errMsg}</p>
                                            <input
                                                name="renewpassword"
                                                id="renewpassword"
                                                type="password"
                                                className="form-control"
                                                value={reNewPwd}
                                                onChange={(e) => {
                                                    setReNewPwd(e.target.value);
                                                    newPwd === e.target.value ? setErrMsg('') : setErrMsg('Không khớp với mật khẩu mới!');
                                                }}
                                                style={{ fontSize: '14px' }}
                                            />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleReset}>
                        Lưu thay đổi
                    </Button>
                </Modal.Footer>
            </Modal>
        </>

    )
}

export default ResetPassword;