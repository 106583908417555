import Table from 'react-bootstrap/Table';
import Header from '../../layout/Header';
import Sidebar from '../../layout/SideBar';
import Dropdown from 'react-bootstrap/Dropdown';
import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import WarehouseDetail from './WarehouseDetail';
import CreateWarehouse from './CreateWarehouse';
import EditWarehouse from './EditWarehouse';

// Be sure to include styles at some point, probably during your bootstraping
import '@trendmicro/react-paginations/dist/react-paginations.css';
import Setting from '../../Admin/system/Setting';
import { getWarehouseDetailApi, getWarehouseListAdminApi } from '../../apiCaller/services';


const WarehouseList = () => {

    const [whList, setWhList] = useState([]);
    const [pageCount, setPageCount] = useState(1);
    const accessToken = localStorage.getItem("accessToken");
    const [warehouseDetail, setWarehouseDetail] = useState([]);
    const [showDetail, setShowDetail] = useState(false);

    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <a
            href=""
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
            style={{ color: '#000000' }}
        >
            {children}
        </a>
    ));

    const fetchWhList = async (page) => {
        const whData = await getWarehouseListAdminApi(page, accessToken);
        setWhList(whData.list);
        setPageCount(whData.totalpage);
    }

    const fetchWhDetail = async (item) => {
        try {
            const whData = await getWarehouseDetailApi(item.warehouseId, accessToken);
            setWarehouseDetail(whData.data);
        } catch (err) {

        }
    }

    useEffect(() => {
        fetchWhList(1);
    }, []);

    const handlePageClick = (event) => {
        fetchWhList(+event.selected + 1);
    };

    let rows2;

    if (whList && whList.length < 10) {
        rows2 = (
            <>
                {
                    [...Array(10 - whList.length)].map((_, i) => (
                        <tr style={{ height: '30px' }} key={whList.length + i}>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    ))
                }
            </>
        );
    };

    return (
        <section>
            <div className="container-fluid w-100 row p-0 m-0" style={{ backgroundColor: '#F5F5F5' }}>
                <Header />
                <div className="row">
                    <Sidebar />
                    <div className='col-lg-10 col-md-8 col-12 ps-5' style={{ fontSize: '14px', lineHeight: '20px' }}>
                        <Setting />
                        <div className='my-5'>
                            <h1>Quản lý kho</h1>
                        </div>
                        <div className='w-100 '>
                            <div className='d-flex justify-content-between align-items-center bg-white list-header p-4'>
                                <h2 className='col-lg-6'>Danh sách kho</h2>
                                <div className='d-flex justify-content-end col-lg-6'>
                                    <ReactPaginate
                                        breakLabel="..."
                                        nextLabel=">"
                                        onPageChange={handlePageClick}
                                        pageRangeDisplayed={5}
                                        pageCount={pageCount}
                                        previousLabel="<"
                                        renderOnZeroPageCount={null}
                                        marginPagesDisplayed={2}
                                        pageClassName="page-item"
                                        pageLinkClassName="page-link"
                                        previousClassName="page-item"
                                        previousLinkClassName="page-link"
                                        nextClassName="page-item"
                                        nextLinkClassName="page-link"
                                        breakClassName="page-item"
                                        breakLinkClassName="page-link"
                                        containerClassName="pagination"
                                        activeClassName="active"
                                    />
                                    {/* <button className='btn btn-danger ms ms-4' style={{ fontSize: '14px' }} onClick={handleShow3}>+&nbsp;&nbsp;Tạo kho</button>
                                    <Modal show={show3} onHide={handleClose3}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Tạo kho</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <CreateWarehouse />
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="primary" onClick={handleClose3}>
                                                Tạo kho
                                            </Button>
                                        </Modal.Footer>
                                    </Modal> */}
                                </div>
                            </div>
                            <div className='bg-white p-4'>
                                <Table responsive bordered striped cellSpacing={0}>
                                    <thead>
                                        <tr>
                                            <th style={{ width: '10%' }}>MÃ KHO</th>
                                            <th style={{ width: '20%' }}>TÊN KHO</th>
                                            <th style={{ width: '70%' }}>ĐỊA CHỈ</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {whList.map((item, index) => (
                                            <tr height='30px' key={`request-${index}`}>
                                                <td>{item.warehouseId}</td>
                                                <td>{item.warehouseName}</td>
                                                <td className='d-flex justify-content-between align-items-center'>
                                                    <p>{item.address.house}, {item.address.ward.wardName}, {item.address.district.districtName}, {item.address.city.cityName} </p>
                                                    <Dropdown>
                                                        <Dropdown.Toggle as={CustomToggle}>
                                                            <i className="fa-solid fa-ellipsis"></i>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item 
                                                            href="" 
                                                            onClick={() => {
                                                                setShowDetail(true);
                                                                fetchWhDetail(item);
                                                            }}
                                                            >
                                                                Xem chi tiết
                                                                </Dropdown.Item>
                                                            <WarehouseDetail 
                                                                show={showDetail}
                                                                setShow={setShowDetail}
                                                                warehouseDetail={warehouseDetail}
                                                            />
                                                            {/* <Dropdown.Item href="" onClick={handleShow2}>Chỉnh sửa</Dropdown.Item>
                                                            <Modal show={show2} onHide={handleClose2}>
                                                                <Modal.Header closeButton>
                                                                    <Modal.Title>Chỉnh sửa kho</Modal.Title>
                                                                </Modal.Header>
                                                                <Modal.Body>
                                                                    <EditWarehouse />
                                                                </Modal.Body>
                                                                <Modal.Footer>
                                                                    <Button variant="primary" onClick={handleClose2}>
                                                                        Lưu kho
                                                                    </Button>
                                                                </Modal.Footer>
                                                            </Modal> */}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </td>
                                            </tr>
                                        ))}
                                        {rows2}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>

    )
}

export default WarehouseList;