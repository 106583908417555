import Header from "../../layout/Header";
import Sidebar from "../../layout/SideBar";
import Table from 'react-bootstrap/Table';
import React, { useState } from 'react';
import ReactPaginate from 'react-paginate';
import WarehouseChart from "./WarehouseChart";


const WarehouseReport = () => {

    const options = [
        'Nguyen Van A',
        'Tran Van B',
        'Le Thi C'
    ];

    const defaultOption = options[0];

    const onSelect = () => {

    };

    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <a
            href=""
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
            style={{ color: '#000000' }}
        >
            {children}
        </a>
    ));

    const items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];

    function Items({ currentItems }) {
        return (
            <>
                {currentItems &&
                    currentItems.map((item) => (
                        <div>
                            <h3>Item #{item}</h3>
                        </div>
                    ))}
            </>
        );
    }

    function PaginatedItems({ itemsPerPage }) {
        // Here we use item offsets; we could also use page offsets
        // following the API or data you're working with.
        const [itemOffset, setItemOffset] = useState(0);

        // Simulate fetching items from another resources.
        // (This could be items from props; or items loaded in a local state
        // from an API endpoint with useEffect and useState)
        const endOffset = itemOffset + itemsPerPage;
        console.log(`Loading items from ${itemOffset} to ${endOffset}`);
        const currentItems = items.slice(itemOffset, endOffset);
        const pageCount = Math.ceil(items.length / itemsPerPage);

        // Invoke when user click to request another page.
        const handlePageClick = (event) => {
            const newOffset = (event.selected * itemsPerPage) % items.length;
            console.log(
                `User requested page number ${event.selected}, which is offset ${newOffset}`
            );
            setItemOffset(newOffset);
        };

        return (
            <>
                <ReactPaginate
                    breakLabel="..."
                    nextLabel=">"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageCount}
                    previousLabel="<"
                    renderOnZeroPageCount={null}
                    marginPagesDisplayed={2}
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    containerClassName="pagination"
                    activeClassName="active"
                />
            </>
        );
    }

    return (
        <section>
            <div className="container-fluid w-100 row p-0 m-0" style={{ backgroundColor: '#FAFAFA' }}>
                <Header />
                <div className="row">
                    <Sidebar />
                    <div className="col-lg-10 col-md-8">
                        <WarehouseChart />
                        <div>
                            <div className='mx-5 my-3 row'>
                                <h2 className='col-lg-6 py-4'>Báo cáo kho</h2>
                                <div className='d-flex justify-content-between col-lg-6 py-4'>
                                    <PaginatedItems itemsPerPage={10} />
                                    <select className='p-2 h-75'>
                                        <option value={"1"}>Tháng 1</option>
                                        <option value={"2"}>Tháng 2</option>
                                        <option value={"3"}>Tháng 3</option>
                                        <option value={"4"}>Tháng 4</option>
                                        <option value={"1"}>Tháng 5</option>
                                        <option value={"1"}>Tháng 6</option>
                                        <option value={"1"}>Tháng 7</option>
                                        <option value={"1"}>Tháng 8</option>
                                        <option value={"1"}>Tháng 9</option>
                                        <option value={"1"}>Tháng 10</option>
                                        <option value={"1"}>Tháng 11</option>
                                        <option value={"1"}>Tháng 12</option>
                                    </select>
                                </div>
                            </div>
                            <div className='' style={{ backgroundColor: '#FFFFFF' }}>
                                <Table responsive bordered cellSpacing={0}>
                                    <thead>
                                        <tr>
                                            <th style={{ width: '15%' }}>TÊN KHO</th>
                                            <th style={{ width: '15%' }}>SỐ LƯỢNG ĐƠN</th>
                                            <th style={{ width: '15%' }}>DOANH THU</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr height='30px'>
                                            <td>Thanh Xuân</td>
                                            <td>26371</td>
                                            <td>1000000</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    )
}

export default WarehouseReport;