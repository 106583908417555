import Table from 'react-bootstrap/Table';
import Header from '../../layout/Header';
import Sidebar from '../../layout/SideBar';
import Dropdown from 'react-bootstrap/Dropdown';
import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import CustomerDetail from './CustomerDetail';

// Be sure to include styles at some point, probably during your bootstraping
import '@trendmicro/react-paginations/dist/react-paginations.css';
import { getAllCustomerInWarehouseApi, getCustomerDetailApi } from '../../apiCaller/services';


const CustomerList = () => {

    const accessToken = localStorage.getItem("accessToken");
    const [showDetail, setShowDetail] = useState(false);
    const [customerList, setCustomerList] = useState([]);
    const [customerDetail, setCustomerDetail] = useState([]);
    const [selectedCustomerStt, setSelectedCustomerStt] = useState(new Array(0).fill(0));
    const [pageCount, setPageCount] = useState(1);

    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <a
            href=""
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
            style={{ color: '#000000' }}
        >
            {children}
        </a>
    ));

    const fetchCustomer = async (page) => {
        try {
            const cusData = await getAllCustomerInWarehouseApi(page, accessToken);
            setCustomerList(cusData?.data);
            setPageCount(cusData?.pageing?.totalPages);
        } catch (err) {

        }
    }

    const fetchCustomerDetail = async (item) => {
        try {
            const cusDetail = await getCustomerDetailApi(item.cusid, accessToken);
            setCustomerDetail(cusDetail[0]);
        } catch (err) {

        }
    }

    useEffect(() => {
        fetchCustomer(1);
    }, []);

    const handlePageClick = (event) => {
        fetchCustomer(+event.selected + 1);
    };

    let rows2;

    if (customerList && customerList.length < 10) {
        rows2 = (
            <>
                {
                    [...Array(10 - customerList.length)].map((_, i) => (
                        <tr height="30px" key={customerList.length + i}>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    ))
                }
            </>
        );
    };

    return (
        <section>
            <div className="container-fluid w-100 row p-0 m-0" style={{ backgroundColor: '#F5F5F5' }}>
                <Header />
                <div className="row">
                    <Sidebar />
                    <div className='col-lg-10 col-md-8 col-12 ps-5' style={{ fontSize: '14px', lineHeight: '20px' }}>
                        <div className='my-5'>
                            <h1>Quản lý khách hàng</h1>
                        </div>
                        <div className='w-100 '>
                            <div className='d-flex justify-content-between align-items-center row bg-white list-header p-4'>
                                <h2 className='col-lg-6 col-12'>Danh sách khách hàng</h2>
                                <div className='d-md-flex flex-row-reverse col-lg-6 py-4'>
                                    <div className='d-flex justify-content-end mt-3 mt-md-0'>
                                        <ReactPaginate
                                            breakLabel="..."
                                            nextLabel=">"
                                            onPageChange={handlePageClick}
                                            pageRangeDisplayed={5}
                                            pageCount={pageCount}
                                            previousLabel="<"
                                            renderOnZeroPageCount={null}
                                            marginPagesDisplayed={2}
                                            pageClassName="page-item"
                                            pageLinkClassName="page-link"
                                            previousClassName="page-item"
                                            previousLinkClassName="page-link"
                                            nextClassName="page-item"
                                            nextLinkClassName="page-link"
                                            breakClassName="page-item"
                                            breakLinkClassName="page-link"
                                            containerClassName="pagination"
                                            activeClassName="active"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='bg-white p-4'>
                                <Table responsive bordered striped cellSpacing={0}>
                                    <thead>
                                        <tr>
                                            {/* <th style={{ width: '10%' }}>MÃ KHÁCH HÀNG</th> */}
                                            <th style={{ width: '20%' }}>TÊN KHÁCH HÀNG</th>
                                            <th style={{ width: '20%' }}>EMAIL</th>
                                            <th style={{ width: '15%' }}>SỐ ĐIỆN THOẠI</th>
                                            <th style={{ width: '15%' }}>ĐỊA CHỈ</th>
                                            <th style={{ width: '15%' }}>TÌNH TRẠNG</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {customerList.map((item, index) => (
                                            <tr height='30px' key={`request-${index}`}>
                                                {/* <td>{item.cusid}</td> */}
                                                <td>{item.customername}</td>
                                                <td>
                                                    {item.customeremail}
                                                </td>
                                                <td>
                                                    {item.customerphone}
                                                </td>
                                                <td>
                                                    {item.wardname}, {item.districtname}, {item.cityname}
                                                </td>
                                                <td className='position-relative'>
                                                    <p className='pe-5'>{item.statusname}</p>
                                                    <Dropdown className='position-absolute top-0 end-0 pt-2 pe-2'>
                                                        <Dropdown.Toggle as={CustomToggle}>
                                                            <i className="fa-solid fa-ellipsis"></i>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item
                                                                href=""
                                                                onClick={() => {
                                                                    setShowDetail(true);
                                                                    fetchCustomerDetail(item);
                                                                }}
                                                            >
                                                                Xem chi tiết
                                                            </Dropdown.Item>
                                                            <CustomerDetail
                                                                show={showDetail}
                                                                setShow={setShowDetail}
                                                                customerDetail={customerDetail}
                                                            />
                                                            {/* <Dropdown.Item href="" onClick={handleShow2}>Chỉnh sửa</Dropdown.Item>
                                                            <Modal show={show2} onHide={handleClose2}>
                                                                <Modal.Header closeButton>
                                                                    <Modal.Title>Chỉnh sửa khách hàng</Modal.Title>
                                                                </Modal.Header>
                                                                <Modal.Body>
                                                                    <EditCustomer />
                                                                </Modal.Body>
                                                                <Modal.Footer>
                                                                    <Button variant="primary" onClick={handleClose2}>
                                                                        Lưu thay đổi
                                                                    </Button>
                                                                </Modal.Footer>
                                                            </Modal> */}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </td>
                                            </tr>
                                        ))}
                                        {rows2}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>

    )
}

export default CustomerList;