import Header from "../../layout/Header";
import Sidebar from "../../layout/SideBar";
import React, { useEffect, useState } from 'react';
import { managerRevenueReportGraphApi } from '../../apiCaller/services.js';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    scales,
} from 'chart.js';
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const MRevenueReport = () => {

    const accessToken = localStorage.getItem("accessToken");
    const [revenueReport, setRevenueReport] = useState({});
    // const [pageCount, setPageCount] = useState(1);
    // const [month, setMonth] = useState(Number((new Date()).getMonth() + 1));

    const fetchRevenueGraph = async () => {
        try {
            const rgData = await managerRevenueReportGraphApi(accessToken);
            const newArray = [];
            rgData.map((item) => (
                newArray.push(item?.revenue)
            ))
            setRevenueReport(newArray);
        } catch (err) {

        }
    }

    useEffect(() => {
        fetchRevenueGraph();
    }, []);

    const options = {
        responsive: true,
        plugins: {
            legend: {
                // position: 'top' as const,
            },
            title: {
                display: true,
                text: 'Báo cáo doanh thu',
            },
        },
        scales: {
            y: {
                beginAtZero: true,
                display: true,
                ticks: {
                    maxTicksLimit: 8
                }
            }
        }
    };

    const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    const data = {
        labels,
        datasets: [
            {
                label: 'Doanh thu',
                data: revenueReport,
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
        ],
    };

    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <a
            href=""
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
            style={{ color: '#000000' }}
        >
            {children}
        </a>
    ));

    // let rows2;

    // if (areaReportList && areaReportList.length < 10) {
    //     rows2 = (
    //         <>
    //             {
    //                 [...Array(10 - areaReportList.length)].map((_, i) => (
    //                     <tr style={{ height: '30px' }} key={areaReportList.length + i}>
    //                         <td></td>
    //                         <td></td>
    //                     </tr>
    //                 ))
    //             }
    //         </>
    //     );
    // };

    return (
        <section>
            <div className="container-fluid w-100 row p-0 m-0" style={{ backgroundColor: '#FAFAFA' }}>
                <Header />
                <div className="row">
                    <Sidebar />
                    <div className="col-lg-10 col-md-8 p-5">
                        <div className="d-flex my-5">
                            <h1 className=''><a href="#/report-manager">Báo cáo</a> &gt;&nbsp;</h1><h2 className="p-2">Doanh thu</h2>
                        </div>
                        <div>
                            <Line options={options} data={data} />
                            {/* <div className='mx-5 my-3 row'>
                                <div className="col-lg-6"></div>
                                <div className='d-flex justify-content-between col-lg-6 py-4'>
                                    <div className="d-flex justify-content-end">
                                        <select
                                            value={month}
                                            onChange={e => {
                                                setMonth(Number(e.target.value));
                                                fetchAreaReport(1, Number(e.target.value));
                                            }}
                                            className='p-2 mt-md-0 mt-3'
                                            style={{ fontSize: '16px' }}
                                        >
                                            <option value={"1"}>Tháng 1</option>
                                            <option value={"2"}>Tháng 2</option>
                                            <option value={"3"}>Tháng 3</option>
                                            <option value={"4"}>Tháng 4</option>
                                            <option value={"5"}>Tháng 5</option>
                                            <option value={"6"}>Tháng 6</option>
                                            <option value={"7"}>Tháng 7</option>
                                            <option value={"8"}>Tháng 8</option>
                                            <option value={"9"}>Tháng 9</option>
                                            <option value={"10"}>Tháng 10</option>
                                            <option value={"11"}>Tháng 11</option>
                                            <option value={"12"}>Tháng 12</option>
                                        </select>
                                    </div>
                                    <div className="d-flex justify-content-end mt-3 mt-md-0">
                                        <ReactPaginate
                                            breakLabel="..."
                                            nextLabel=">"
                                            onPageChange={handlePageClick}
                                            pageRangeDisplayed={20}
                                            pageCount={pageCount}
                                            previousLabel="<"
                                            renderOnZeroPageCount={null}
                                            marginPagesDisplayed={2}
                                            pageClassName="page-item"
                                            pageLinkClassName="page-link"
                                            previousClassName="page-item"
                                            previousLinkClassName="page-link"
                                            nextClassName="page-item"
                                            nextLinkClassName="page-link"
                                            breakClassName="page-item"
                                            breakLinkClassName="page-link"
                                            containerClassName="pagination"
                                            activeClassName="active"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='' style={{ backgroundColor: '#FFFFFF' }}>
                                <Table responsive bordered cellSpacing={0}>
                                    <thead>
                                        <tr>
                                            <th style={{ width: '15%' }}>MÃ YÊU CẦU</th>
                                            <th style={{ width: '15%' }}>MÃ ĐƠN HÀNG</th>
                                            <th style={{ width: '15%' }}>NHÂN VIÊN VẬN CHUYỂN</th>
                                            <th style={{ width: '15%' }}>LOẠI YÊU CẦU</th>
                                            <th style={{ width: '15%' }}>TÌNH TRẠNG</th>
                                            <th style={{ width: '15%' }}>GHI CHÚ</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr height='30px'>
                                            <td>1</td>
                                            <td>26371628367</td>
                                            <td>
                                                <div className='w-100'>
                                                    Hoang Manh D
                                                </div>
                                            </td>
                                            <td>Yêu cầu</td>
                                            <td>Đang xử lý</td>
                                            <td className='d-flex justify-content-between'>
                                                <div className=''>abc.</div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div> */}
                        </div>
                    </div>
                </div>

            </div>
        </section>
    )
}

export default MRevenueReport;