import { useEffect, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import ReactToPrint from "react-to-print";
import QRCode from "react-qr-code";

const PrintQr = (props) => {

    const { Items, show, setShow } = props;
    let componentRef = useRef();

    useEffect(() => {

    }, [Items])

    return (
        <>
            <Modal
                size="lg"
                show={show}
                backdrop="static"
                onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Print QR</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row p-4" ref={e => (componentRef = e)}>
                        {
                            Items.map((item, index) => (
                                <div key={index} className="col-3 p-2 text-center">
                                    <QRCode
                                        value={item}
                                        className="p-2"
                                        style={{ height: "auto", width: "128px" }}
                                    />
                                </div>
                            ))
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <ReactToPrint
                        trigger={() => <Button>In mã</Button>}
                        content={() => componentRef}
                    />
                </Modal.Footer>
            </Modal>
        </>
    )

}

export default PrintQr;