import Table from 'react-bootstrap/Table';
import Header from '../../layout/Header';
import Sidebar from '../../layout/SideBar';
import Dropdown from 'react-bootstrap/Dropdown';
import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import ReactPaginate from 'react-paginate';
import EditOrder from './EditOrder';
import OrderDetail from './OrderDetail';

// Be sure to include styles at some point, probably during your bootstraping
import '@trendmicro/react-paginations/dist/react-paginations.css';
import { assignShipperToOrder, getAccountDetailApi, getOrderListApi, getSearchOrderApi, getShipperListApi } from '../../apiCaller/services';


const OrderList = () => {

    const accessToken = localStorage.getItem("accessToken");
    const [orderList, setOrderList] = useState([]);
    const [orderDetail, setOrderDetail] = useState([]);
    const [shipperList, setShipperList] = useState([]);
    const [selectedShippers, setSelectedShippers] = useState(new Array(0).fill(0));
    const [pageCount, setPageCount] = useState(1);
    // const [showModalEditOrder, setShowModalEditOrder] = useState(false);
    const [showModalOrderDetail, setShowModalOrderDetail] = useState(false);
    const [whStaffId, setStaffWhId] = useState(0);
    const [selectedStt, setSelectedStt] = useState('');
    const [searchText, setSearchText] = useState('');

    const fetchStaff = async () => {
        const staff = await getAccountDetailApi(accessToken);
        setStaffWhId(staff.data.staff.warehouseId);
    }

    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <a
            href=""
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
            style={{ color: '#000000' }}
        >
            {children}
        </a>
    ));

    const fetchOrder = async (page) => {
        const orderData = await getOrderListApi(page, accessToken);
        setOrderList(orderData.data);
        setPageCount(orderData.paging.totalPages);
    };

    const fetchShipper = async () => {
        const shipperData = await getShipperListApi(accessToken);
        setShipperList(shipperData);
    };

    const fetchAssignShipper = async (index, orderId, shipperId) => {
        if (window.confirm(`Bạn có chắc muốn chọn để làm nhân viên nhận hàng?`)) {
            try {
                const res = await assignShipperToOrder(orderId, shipperId, accessToken);
                if (res) {
                    console.log(res);
                }
                fetchOrder(1);
            } catch (err) {
                console.log(err.response);
            }
        } else {
            const newSelectedShippers = [...selectedShippers];
            newSelectedShippers[index] = 0;
            setSelectedShippers(newSelectedShippers);
        }
    };

    const removeExtraSpace = (s) => s.trim().split(/ +/).join(' ');

    const searchOrder = async (page) => {
        const res = await getSearchOrderApi(page, removeExtraSpace(searchText), Number(selectedStt), accessToken);
        setOrderList(res.orders);
        setPageCount(res.paging.totalPages);
    }

    const handlePageClick = (event) => {
        searchOrder(+event.selected + 1);
    };

    useEffect(() => {
        fetchOrder(1);
        fetchShipper();
        fetchStaff();
        setSelectedShippers(new Array(orderList.length).fill(0));
        const interval = setInterval(() => {
            window.location.reload();
        }, 300000);

        return () => clearInterval(interval);
    }, []);

    const getStatusColor = (status) => {
        if (Number(status) === 1 || Number(status) === 2) {
            return 'blue'
        } else if (Number(status) === 9) {
            return 'green'
        } else if (Number(status) === 10) {
            return 'red'
        } else {
            return 'black'
        }
    };

    const handleClickSearch = () => {
        searchOrder(1);
    }

    let rows2;

    if (orderList && orderList.length < 10) {
        rows2 = (
            <>
                {
                    [...Array(10 - orderList.length)].map((_, i) => (
                        <tr style={{ height: '30px' }} key={orderList.length + i}>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    ))
                }
            </>
        );
    };

    return (
        <section>
            <div className="container-fluid w-100 row p-0 m-0" style={{ backgroundColor: '#F5F5F5' }}>
                <Header />
                <div className="row">
                    <Sidebar />
                    <div className='col-lg-10 col-md-8 col-12 ps-5' style={{ fontSize: '14px', lineHeight: '20px' }}>
                        <div className='my-5 row'>
                            <h1 className='col-lg-5'>Quản lý đơn hàng</h1>
                            <form className='col-lg-7 row' onSubmit={(e) => {
                                e.preventDefault();
                                handleClickSearch();
                            }}>
                                <div className='d-flex flex-row-reverse'>
                                    <select
                                        className='mt-3 p-2'
                                        style={{ fontSize: '16px' }}
                                        onChange={
                                            (e) => {
                                                setSelectedStt(e.target.value);
                                            }
                                        }
                                    >
                                        <option value="">Tình trạng</option>
                                        <option value="1">Created</option>
                                        <option value="2">Await pickup</option>
                                        <option value="3">Picked up</option>
                                        <option value="4">Arrived pickup warehouse</option>
                                        <option value="5">On transit</option>
                                        <option value="6">Arrived deliver warehouse</option>
                                        <option value="7">Await Delivery</option>
                                        <option value="8">On Delivery</option>
                                        <option value="9">Delivered</option>
                                        <option value="10">Canceled</option>
                                    </select>
                                </div>
                                <div className='d-flex flex-row-reverse mt-3 position-relative mb-5'>
                                    <input
                                        type='text'
                                        className="col-10 p-3 rounded-pill position-absolute"
                                        id="orderId"
                                        name="orderId"
                                        autoComplete="off"
                                        onChange={(e) => setSearchText(e.target.value)}
                                        value={searchText}
                                        aria-describedby="orderId"
                                        style={{ fontSize: '16px' }}
                                    />
                                    <Button
                                        type='button'
                                        style={{ fontSize: '16px' }}
                                        variant='light'
                                        onClick={(e) => {
                                            e.preventDefault();
                                            handleClickSearch();
                                        }}
                                        className='rounded-circle position-absolute me-2 mt-2 border border-0'
                                    ><i className="fa-solid fa-magnifying-glass"></i></Button>
                                </div>
                            </form>
                        </div>
                        <div className='w-100 '>
                            <div className='d-flex justify-content-between align-items-center row bg-white list-header p-4'>
                                <h2 className='col-lg-6 col-12'>Danh sách đơn hàng</h2>
                                <div className='d-md-flex flex-row-reverse col-lg-6 py-4'>
                                    <div className='d-flex justify-content-end mt-3 mt-md-0'>
                                        <ReactPaginate
                                            breakLabel="..."
                                            nextLabel=">"
                                            onPageChange={handlePageClick}
                                            pageRangeDisplayed={20}
                                            pageCount={pageCount}
                                            previousLabel="<"
                                            renderOnZeroPageCount={null}
                                            marginPagesDisplayed={2}
                                            pageClassName="page-item"
                                            pageLinkClassName="page-link"
                                            previousClassName="page-item"
                                            previousLinkClassName="page-link"
                                            nextClassName="page-item"
                                            nextLinkClassName="page-link"
                                            breakClassName="page-item"
                                            breakLinkClassName="page-link"
                                            containerClassName="pagination"
                                            activeClassName="active"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='bg-white p-4'>
                                <Table responsive bordered striped cellSpacing={0}>
                                    <thead>
                                        <tr>
                                            <th style={{ width: '10%' }}>MÃ ĐƠN HÀNG</th>
                                            <th style={{ width: '15%' }}>TÌNH TRẠNG</th>
                                            <th style={{ width: '15%' }}>TÊN KHÁCH HÀNG</th>
                                            <th style={{ width: '15%' }}>SỐ ĐIỆN THOẠI</th>
                                            <th style={{ width: '15%' }}>ĐỊA CHỈ</th>
                                            <th style={{ width: '15%' }}>NHÂN VIÊN</th>
                                            <th style={{ width: '15%' }}>THÀNH TIỀN</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {orderList.map((item, index) => (
                                            <tr height='30px' key={`request-${index}`}>
                                                <td>{item.orderId}</td>
                                                <td style={{ color: getStatusColor(item.statusId) }}>{item.status}</td>
                                                {
                                                    (item.pickUpdateWarehouseId === whStaffId) ?
                                                        <>
                                                            <td>
                                                                {item.senderName}
                                                            </td>
                                                            <td>
                                                                {item.pickupPhoneNumber}
                                                            </td>
                                                            <td>
                                                                {item.pickupAddress}
                                                            </td>
                                                            <td>
                                                                {item.status === 'Created' ?
                                                                    <select
                                                                        value={selectedShippers[index]}
                                                                        onChange={(e) => {
                                                                            setOrderDetail(item);
                                                                            const newSelectedShippers = [...selectedShippers];
                                                                            newSelectedShippers[index] = e.target.value;
                                                                            setSelectedShippers(newSelectedShippers);
                                                                            fetchAssignShipper(index, item.orderId, e.target.value);
                                                                        }}
                                                                    >
                                                                        <option value={0}>Chọn nhân viên</option>
                                                                        {shipperList.map((shipper, index) => (
                                                                            <option key={`shipper-${index}`} value={shipper.staffId}>{shipper.fullname}</option>
                                                                        ))}
                                                                    </select>
                                                                    : item.pickupStaffName
                                                                }
                                                            </td>
                                                        </>
                                                        : <>
                                                            <td>
                                                                {item.receiverName}
                                                            </td>
                                                            <td>
                                                                {item.deliverPhoneNumber}
                                                            </td>
                                                            <td>
                                                                {item.deliverAddress}
                                                            </td>
                                                            <td>
                                                                {item.status === 'Arrived deliver warehouse' ?
                                                                    <select
                                                                        value={selectedShippers[index]}
                                                                        onChange={(e) => {
                                                                            setOrderDetail(item);
                                                                            const newSelectedShippers = [...selectedShippers];
                                                                            newSelectedShippers[index] = e.target.value;
                                                                            setSelectedShippers(newSelectedShippers);
                                                                            fetchAssignShipper(index, item.orderId, e.target.value);
                                                                        }}
                                                                    >
                                                                        <option value={0}>Chọn nhân viên</option>
                                                                        {shipperList.map((shipper, index) => (
                                                                            <option key={`shipper-${index}`} value={shipper.staffId}>{shipper.fullname}</option>
                                                                        ))}
                                                                    </select>
                                                                    : item.deliverStaffName
                                                                }
                                                            </td>
                                                        </>
                                                }
                                                <td className='position-relative'>
                                                    <p className='pe-5'>{Number(item.price).toLocaleString()}</p>
                                                    <Dropdown className='position-absolute top-0 end-0 pt-2 pe-2'>
                                                        <Dropdown.Toggle as={CustomToggle}>
                                                            <i className="fa-solid fa-ellipsis"></i>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item href=""
                                                                onClick={() => {
                                                                    setShowModalOrderDetail(true);
                                                                    setOrderDetail(item);
                                                                }}
                                                            >
                                                                Xem chi tiết
                                                            </Dropdown.Item>
                                                            <OrderDetail
                                                                orderDetail={orderDetail}
                                                                show={showModalOrderDetail}
                                                                setShow={setShowModalOrderDetail}
                                                            // setShowModalEditOrder={setShowModalEditOrder}
                                                            />
                                                            {/* <Dropdown.Item href=""
                                                            onClick={() => setShowModalEditOrder(true)}
                                                        >
                                                            Chỉnh sửa
                                                        </Dropdown.Item>
                                                        <EditOrder
                                                            orderDetail={orderDetail}
                                                            fetchOrder={fetchOrder}
                                                            show={showModalEditOrder}
                                                            setShow={setShowModalEditOrder}
                                                        /> */}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </td>
                                            </tr>
                                        ))}
                                        {rows2}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>

    )
}

export default OrderList;