import React, { useEffect, useRef, useState } from "react";
import Banner from "../layout/Banner";
import { changePasswordApi } from "../apiCaller/services";
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ChangePassword = () => {

    const location = useLocation();
    const from = location.state?.from?.pathname || "/";

    const pwdRef = useRef();

    const navigate = useNavigate();

    const email = localStorage.getItem("email");
    const [rePwd, setRePwd] = useState('');
    const [pwd, setPwd] = useState('');
    const [errPwd, setErrPwd] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const isLogin = localStorage.getItem("role");
    const [showPassword, setShowPassword] = useState(false);
    const [showRePassword, setShowRePassword] = useState(false);
    const isValidPassword = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,32}$/g;

    const validatePassword = (password) => {
        return password.match(isValidPassword);
    };

    useEffect(() => {
        pwdRef.current.focus();
        if (isLogin !== null) {
            if (isLogin === "ADMIN") {
                navigate('report-admin');
            } else {
                navigate('request');
            }
        }
        if (email === null) {
            navigate('/');
        }
    }, [])

    const handleShowPasswordClick = () => {
        setShowPassword(!showPassword);
    };

    const handleShowRePasswordClick = () => {
        setShowRePassword(!showRePassword);
    }

    const handleSubmit = async (e) => {

        e.preventDefault();
        setIsSubmitting(true);
        if (pwd === rePwd && validatePassword(pwd)) {
            try {
                const res = (await changePasswordApi(email, pwd));
                if (res) {
                    toast.success("Tạo lại mật khẩu thành công!");
                    localStorage.removeItem("OTP");
                    localStorage.removeItem("email");
                    navigate('/');
                } else {
                    toast.error("Tạo lại mật khẩu thất bại!");
                }
            } catch (err) {
                setPwd('');
                if (!err?.response) {
                    toast.error(err);
                } else if (err.response?.status === 400) {
                    toast.error(err.response?.message);
                } else if (err.response?.status === 401) {
                    toast.error(err.response?.message);
                } else { }
                setIsSubmitting(false);
            }
        }
        // console.log('user: ', auth.user)
    }

    return (
        <>
            <section>
                <div className="row container-fluid m-0 p-0 w-100 h-100">
                    <div className="container-fluid col-md-6 col-sm-12 h-100" style={{ marginRight: 0 }}>
                        <div className="w-75 m-auto">
                            <div className="d-flex align-items-center mt-5">
                                <img src="./images/TT-01.png" className="" style={{ width: '50px', height: '50px' }} alt="Logo" />
                                <span className="ms-3" style={{ color: '#FF6827', lineHeight: '44px', fontSize: '36px', fontFamily: 'Inter', fontWeight: 600, wordWrap: 'break-word' }}>Hand2Hand</span>
                            </div>
                            <div className="text-start pt-5">
                                <h1 style={{ color: '#FF6827', lineHeight: '43px', fontSize: '36px', fontFamily: 'Inter', fontWeight: 700, wordWrap: 'break-word' }}>
                                    Hệ thống quản lí giao nhận hàng
                                </h1>
                            </div>
                            <div className="text-start pt-5 my-5">
                                <p style={{ color: '#000000', lineHeight: '22px', fontSize: '18px', fontFamily: 'Inter', fontWeight: 400, wordWrap: 'break-word' }}>
                                    Chào mừng đã trở lại! Vui lòng nhập tài khoản của bạn.
                                </p>
                            </div>
                            <div className="">
                                <div className="" style={{ marginTop: '4rem' }}>
                                    <form action="" method="post">
                                        <div className="mb-3 input-block position-relative">
                                            <label htmlFor="password" className="input-label mb-5 pb-3">Mật khẩu mới</label>
                                            <p className="text-danger" style={{ fontSize: '16px' }}>{errPwd}</p>
                                            <input
                                                type={showPassword ? "text" : "password"}
                                                className="form-control fs-3 mt-5 ps-4 rounded-pill position-absolute"
                                                id="password"
                                                name="password"
                                                ref={pwdRef}
                                                onChange={(e) => {
                                                    setPwd(e.target.value);
                                                    validatePassword(e.target.value) ? setErrPwd('') : setErrPwd('Định dạng password chưa đúng! Password có 8-32 ký tự gồm ít nhất 1 chữ cái in hoa, 1 số và 1 ký tự đặc biệt!');
                                                }}
                                                value={pwd}
                                                style={{ width: '97%' }}
                                                placeholder="Nhập mật khẩu mới"
                                                required
                                            />
                                            <button
                                                type="button"
                                                style={{ backgroundColor: '#fff', fontSize: '14px' }}
                                                className="rounded-circle mt-5 me-4 pt-2 end-0 position-absolute me-2 mt-2 border border-0"
                                                onClick={handleShowPasswordClick}
                                            >
                                                {showPassword ? <i className="fa-solid fa-eye-slash"></i> : <i className="fa-solid fa-eye"></i>}
                                            </button>
                                        </div>
                                        <div className="mb-3 input-block position-relative">
                                            <label htmlFor="rePassword" className="input-label mb-5 pb-3">Nhập lại mật khẩu mới</label>
                                            <p className="text-danger" style={{ fontSize: '16px' }}>{errMsg}</p>
                                            <input
                                                type={showRePassword ? "text" : "password"}
                                                className="form-control fs-3 mt-5 ps-4 rounded-pill position-absolute"
                                                id="password"
                                                name="password"
                                                onChange={(e) => {
                                                    setRePwd(e.target.value);
                                                    pwd === e.target.value ? setErrMsg('') : setErrMsg('Không khớp với mật khẩu đã nhập')
                                                }}
                                                value={rePwd}
                                                style={{ width: '97%' }}
                                                placeholder="Nhập lại mật khẩu mới"
                                                required
                                            />
                                            <button
                                                type="button"
                                                style={{ backgroundColor: '#fff', fontSize: '14px' }}
                                                className="rounded-circle mt-5 me-4 pt-2 end-0 position-absolute me-2 mt-2 border border-0"
                                                onClick={handleShowRePasswordClick}
                                            >
                                                {showRePassword ? <i className="fa-solid fa-eye-slash"></i> : <i className="fa-solid fa-eye"></i>}
                                            </button>
                                        </div>
                                        <button type="submit" onClick={handleSubmit} className="btn fs-3 mb-5" disabled={isSubmitting} style={{ width: '100%', backgroundColor: '#FF6827', borderRadius: '100px', color: '#ffffff' }}>
                                            {isSubmitting ? 'Đang đổi mật khẩu...' : 'Lưu mật khẩu'}
                                        </button>
                                    </form>
                                </div>
                                <div className="row text-center my-5 fs-3">
                                    <div className="col-4 d-flex flex-row-reverse">
                                        <a href="#" className="text-danger">@H2H</a>
                                    </div>
                                    <div className="col-4 fs-3">
                                        <a href="#" className="text-danger">Contact</a>
                                    </div>
                                    <div className="col-4 d-flex flex-row fs-3">
                                        <a href="#" className="text-danger">Privacy & Terms</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 banner-hide m-0 p-0">
                        <Banner />
                    </div>
                </div>
            </section>
        </>
    );
}

export default ChangePassword;