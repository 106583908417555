import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import DropDown from 'react-bootstrap/Dropdown';
import { useEffect, useState } from 'react';
import Information from '../manager/profile/Information';
import { getAccountDetailApi, getWarehouseListStaffApi } from '../apiCaller/services';
import EditProfile from '../manager/profile/EditProfile';
// import useAuth from '../context/useAuth';
import { toast } from 'react-toastify';
import ResetPassword from '../manager/profile/Resetpassword';

function Header() {

    const role = localStorage.getItem("role");
    const accessToken = localStorage.getItem("accessToken");
    const [warehouseList, setWarehouseList] = useState([]);
    const [user, setUser] = useState({});
    const [show, setShow] = useState(false);
    const [showResetPwd, setShowResetPwd] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    // const { setAuth } = useAuth();

    const fetchWarehouseList = async () => {
        try {
            const warehouseData = await getWarehouseListStaffApi(accessToken);
        setWarehouseList(warehouseData);
        } catch (err) {
            if (!err?.response) {
                toast.error(err);
            } else {
                toast.error(err.response?.data);
            }
        }
    };

    const fetchUser = async () => {
        try {
            const userData = await getAccountDetailApi(accessToken);
            if (userData && userData.data) {
                setUser(userData?.data?.staff);
            }
        } catch (err) {
            if (!err?.response) {
                toast.error(err);
            } else {
                toast.error(err.response?.data);
            }
        }
    };

    useEffect(() => {
        if (role !== 'ADMIN') {
            fetchUser();
        } else {
            fetchWarehouseList();
        }
    }, [accessToken, role]);

    function handleLogout() {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refresh_token");
        localStorage.removeItem("role");
        // setAuth({});
        window.location.href = "/";
    }

    return (
        <>
            <Navbar expand="md" sticky='top' className="bg-body-tertiary bg-white">
                <Container fluid>
                    <Navbar className="bg-body-tertiary bg-white">
                        <Container>
                            <Navbar.Brand className='d-flex align-items-center'><img src='./images/TT-01.png' alt='Logo' style={{ width: '40px', height: '40px' }} /><p className='h2h ms-2 align-items-center'>Hand2Hand</p></Navbar.Brand>
                        </Container>
                    </Navbar>
                    <Navbar.Toggle />
                    <Navbar.Collapse className="justify-content-end">
                        {role === 'ADMIN' ? (
                            <>
                                {/* <select
                                    value={selectedWarehouse}
                                    onChange={(e) => {
                                        const newSelectedWarehouse = e.target.value;
                                        setSelectedWarehouse(newSelectedWarehouse);
                                    }}
                                    style={{ fontSize: '18px' }}
                                    className="p-2 me-5 navbar"
                                >
                                    <option value={0}>Chọn kho</option>
                                    {warehouseList?.map((warehouse, index) => (
                                        <option key={`warehouse-${index}`} value={warehouse?.wh_warehouse_id}>{warehouse?.warehouse_name}</option>
                                    ))}
                                </select> */}
                                <Navbar.Text>
                                    <span>Xin chào, Bạn đăng nhập với vai trò {role}&nbsp;&nbsp;</span>
                                    <DropDown className='d-inline'>
                                        <DropDown.Toggle className='bg-transparent border-0 shadow-none rounded-circle m-0 p-0' variant="success" id="dropdown-basic">
                                            <img src="./images/avatar.png" className="avatar avatar-md-sm rounded-circle border shadow" alt="Avatar" />
                                        </DropDown.Toggle>

                                        <DropDown.Menu className='mt-5' style={{ left: '-35px' }}>
                                            <div className="flex-1 ms-2">
                                                <span className="d-block mb-1">{user?.fullname}</span>
                                                <small className="text-muted">{user?.email}</small>
                                            </div>
                                            {/* <DropDown.Item href="" onClick={() => setShow(true)}>Thông tin</DropDown.Item>
                                            <Information
                                                show={show}
                                                setShow={setShow}
                                                showEdit={showEdit}
                                                setShowEdit={setShowEdit}
                                                user={user}
                                            />
                                            <EditProfile
                                                show={showEdit}
                                                setShow={setShowEdit}
                                                showProfile={show}
                                                setShowProfile={setShow}
                                                user={user}
                                            /> */}
                                            <DropDown.Item onClick={handleLogout}>Đăng xuất</DropDown.Item>
                                        </DropDown.Menu>
                                    </DropDown>
                                </Navbar.Text>
                            </>
                        )
                            : <Navbar.Text>
                                <span>Xin chào, Bạn đăng nhập với vai trò {role}&nbsp;&nbsp;</span>
                                <DropDown className='d-inline'>
                                    <DropDown.Toggle className='bg-transparent border-0 shadow-none rounded-circle m-0 p-0' variant="success" id="dropdown-basic">
                                        <img src="./images/avatar.png" className="avatar avatar-md-sm rounded-circle border shadow" alt="Avatar" />
                                    </DropDown.Toggle>

                                    <DropDown.Menu className='mt-5' style={{ left: '-60px' }}>
                                        <div className="flex-1 ms-2">
                                            <span className="d-block mb-1">{user?.fullname}</span>
                                            <small className="text-muted">{user?.email}</small>
                                        </div>
                                        <DropDown.Item href="" onClick={() => setShow(true)}>Thông tin</DropDown.Item>
                                        <Information
                                            show={show}
                                            setShow={setShow}
                                            showEdit={showEdit}
                                            setShowEdit={setShowEdit}
                                            user={user}
                                        />
                                        <EditProfile
                                            show={showEdit}
                                            setShow={setShowEdit}
                                            showProfile={show}
                                            setShowProfile={setShow}
                                            user={user}
                                            fetchUser={fetchUser}
                                        />
                                        <DropDown.Item href="" onClick={() => setShowResetPwd(true)}>Đổi mật khẩu</DropDown.Item>
                                        <ResetPassword 
                                            show={showResetPwd}
                                            setShow={setShowResetPwd}
                                        />
                                        <DropDown.Item onClick={handleLogout}>Đăng xuất</DropDown.Item>
                                    </DropDown.Menu>
                                </DropDown>
                            </Navbar.Text>
                        }

                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    );
}

export default Header;