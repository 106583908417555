import Header from "../../layout/Header";
import Sidebar from "../../layout/SideBar";
import Table from 'react-bootstrap/Table';
import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import RevenueChart from "./RevenueChart";
import { adminRevenueTableReportApi } from "../../apiCaller/services";


const RevenueReport = () => {

    const accessToken = localStorage.getItem("accessToken");
    const [revenueReportList, setRevenueReportList] = useState([]);
    const [pageCount, setPageCount] = useState(1);
    const [month, setMonth] = useState(Number((new Date()).getMonth() + 1));

    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <a
            href=""
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
            style={{ color: '#000000' }}
        >
            {children}
        </a>
    ));

    const fetchRevenueReportTable = async (page, month) => {
        const revenueData = await adminRevenueTableReportApi(page, month, accessToken);
        setRevenueReportList(revenueData?.data);
        setPageCount(revenueData?.totalPage);
    };

    useEffect(() => {
        fetchRevenueReportTable(1, month);
    }, [])

    const handlePageClick = (event) => {
        fetchRevenueReportTable(+event.selected + 1, month);
    };

    let rows2;

    if (revenueReportList && revenueReportList.length < 10) {
        rows2 = (
            <>
                {
                    [...Array(10 - revenueReportList.length)].map((_, i) => (
                        <tr style={{ height: '30px' }} key={revenueReportList.length + i}>
                            <td></td>
                            <td></td>
                        </tr>
                    ))
                }
            </>
        );
    };

    return (
        <section>
            <div className="container-fluid w-100 row p-0 m-0" style={{ backgroundColor: '#FAFAFA' }}>
                <Header />
                <div className="row">
                    <Sidebar />
                    <div className="col-lg-10 col-md-8">
                        <div className='mx-5 my-3'>
                            <h2 className=''>Báo cáo doanh thu</h2>
                        </div>
                        <RevenueChart />
                        <div>
                            <div className='mx-5 my-3 row'>
                                <div className="col-lg-6 py-4"></div>
                                <div className='d-md-flex flex-row-reverse col-lg-6 py-4'>
                                    <div className="ms-0 ms-md-4 d-flex justify-content-end">
                                        <select
                                            value={month}
                                            onChange={e => {
                                                setMonth(Number(e.target.value));
                                                fetchRevenueReportTable(1, Number(e.target.value));
                                            }}
                                            className='p-2 mt-md-0 mt-3'
                                            style={{ fontSize: '16px' }}
                                        >
                                            <option value={"1"}>Tháng 1</option>
                                            <option value={"2"}>Tháng 2</option>
                                            <option value={"3"}>Tháng 3</option>
                                            <option value={"4"}>Tháng 4</option>
                                            <option value={"5"}>Tháng 5</option>
                                            <option value={"6"}>Tháng 6</option>
                                            <option value={"7"}>Tháng 7</option>
                                            <option value={"8"}>Tháng 8</option>
                                            <option value={"9"}>Tháng 9</option>
                                            <option value={"10"}>Tháng 10</option>
                                            <option value={"11"}>Tháng 11</option>
                                            <option value={"12"}>Tháng 12</option>
                                        </select>
                                    </div>
                                    <div className="d-flex justify-content-end mt-3 mt-md-0">
                                        <ReactPaginate
                                            breakLabel="..."
                                            nextLabel=">"
                                            onPageChange={handlePageClick}
                                            pageRangeDisplayed={20}
                                            pageCount={pageCount}
                                            previousLabel="<"
                                            renderOnZeroPageCount={null}
                                            marginPagesDisplayed={2}
                                            pageClassName="page-item"
                                            pageLinkClassName="page-link"
                                            previousClassName="page-item"
                                            previousLinkClassName="page-link"
                                            nextClassName="page-item"
                                            nextLinkClassName="page-link"
                                            breakClassName="page-item"
                                            breakLinkClassName="page-link"
                                            containerClassName="pagination"
                                            activeClassName="active"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='' style={{ backgroundColor: '#FFFFFF' }}>
                                <Table responsive bordered cellSpacing={0}>
                                    <thead>
                                        <tr>
                                            <th style={{ width: '15%' }}>TÊN KHO</th>
                                            <th style={{ width: '15%' }}>DOANH THU</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {revenueReportList?.map((item, index) => (
                                            <tr height='30px' key={`request-${index}`}>
                                                <td>{item?.warehouseName}</td>
                                                <td>{Number(item?.revenue).toLocaleString()}</td>
                                            </tr>
                                        ))}
                                        {rows2}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    )
}

export default RevenueReport;