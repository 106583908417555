import _ from "lodash";
import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";

const OrderDetail = (props) => {

    const { orderDetail, show, setShow } = props;
    const [orderId, setOrderId] = useState("");
    const [status, setStatus] = useState("");
    const [sendCus, setSendCus] = useState("");
    const [receiveCus, setReceiveCus] = useState("");
    const [sendPhone, setSendPhone] = useState("");
    const [receivePhone, setReceivePhone] = useState("");
    const [sendAddress, setSendAddress] = useState("");
    const [receiveAddress, setReceiveAddress] = useState("");
    const [pickupShipper, setPickupShipper] = useState("");
    const [deliveryShipper, setDeliveryShipper] = useState("");
    const [image, setImage] = useState("");
    const [price, setPrice] = useState("");

    

    useEffect(() => {
        if (!_.isEmpty(orderDetail)) {
            setOrderId(orderDetail.orderId);
            setStatus(orderDetail.status);
            setSendCus(orderDetail.senderName);
            setReceiveCus(orderDetail.receiverName);
            setSendPhone(orderDetail.pickupPhoneNumber);
            setReceivePhone(orderDetail.deliverPhoneNumber);
            setSendAddress(orderDetail.pickupAddress);
            setReceiveAddress(orderDetail.deliverAddress);
            setPickupShipper(orderDetail.pickupStaffName);
            setDeliveryShipper(orderDetail.deliverStaffName);
            setPrice(orderDetail.price);
        }
    }, [orderDetail])

    return (
        <>
            <Modal
                show={show}
                backdrop="static"
                onHide={() => setShow(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Chi tiết đơn hàng</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="">
                        <section className="row">
                            <div className="pb-3 input-modal col-md-6">
                                <label htmlFor="orderId" className="input-label-modal pb-3">MÃ ĐƠN HÀNG</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="orderId"
                                    name="orderId"
                                    autoComplete="off"
                                    onChange={(e) => setOrderId(e.target.value)}
                                    value={orderId}
                                    aria-describedby="orderId"
                                    style={{ padding: '8px 12px' }}
                                    disabled
                                />
                            </div>
                            <div className="pb-3 input-modal col-md-6">
                                <label htmlFor="status" className="input-label-modal pb-3">TÌNH TRẠNG</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="status"
                                    name="status"
                                    autoComplete="off"
                                    onChange={(e) => setStatus(e.target.value)}
                                    value={status}
                                    aria-describedby="status"
                                    style={{ padding: '8px 12px' }}
                                    disabled
                                />
                            </div>
                            <div className="pb-3 input-modal col-md-6">
                                <label htmlFor="sendCus" className="input-label-modal pb-3">TÊN NGƯỜI GỬI</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="sendCus"
                                    name="sendCus"
                                    autoComplete="off"
                                    onChange={(e) => setSendCus(e.target.value)}
                                    value={sendCus}
                                    aria-describedby="sendCus"
                                    style={{ padding: '8px 12px' }}
                                    disabled
                                />
                            </div>
                            <div className="pb-3 input-modal col-md-6">
                                <label htmlFor="receiveCus" className="input-label-modal pb-3">TÊN NGƯỜI NHẬN</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="receiveCus"
                                    name="receiveCus"
                                    autoComplete="off"
                                    onChange={(e) => setReceiveCus(e.target.value)}
                                    value={receiveCus}
                                    aria-describedby="receiveCus"
                                    style={{ padding: '8px 12px' }}
                                    disabled
                                />
                            </div>
                            <div className="pb-3 input-modal col-md-6">
                                <label htmlFor="sendPhone" className="input-label-modal pb-3">SỐ ĐIỆN THOẠI NGƯỜI GỬI</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="sendPhone"
                                    name="sendPhone"
                                    autoComplete="off"
                                    onChange={(e) => setSendPhone(e.target.value)}
                                    value={sendPhone}
                                    aria-describedby="sendPhone"
                                    style={{ padding: '8px 12px' }}
                                    disabled
                                />
                            </div>
                            <div className="pb-3 input-modal col-md-6">
                                <label htmlFor="receivePhone" className="input-label-modal pb-3">SỐ ĐIỆN THOẠI NGƯỜI NHẬN</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="receivePhone"
                                    name="receivePhone"
                                    autoComplete="off"
                                    onChange={(e) => setReceivePhone(e.target.value)}
                                    value={receivePhone}
                                    aria-describedby="receivePhone"
                                    style={{ padding: '8px 12px' }}
                                    disabled
                                />
                            </div>
                            <div className="pb-3 input-modal col-md-6">
                                <label htmlFor="sendAdd" className="input-label-modal pb-3">ĐỊA CHỈ LẤY HÀNG</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="sendAdd"
                                    name="sendAdd"
                                    autoComplete="off"
                                    onChange={(e) => setSendAddress(e.target.value)}
                                    value={sendAddress}
                                    aria-describedby="sendAdd"
                                    style={{ padding: '8px 12px' }}
                                    disabled
                                />
                            </div>
                            <div className="pb-3 input-modal col-md-6">
                                <label htmlFor="receiveAdd" className="input-label-modal pb-3">ĐỊA CHỈ GIAO HÀNG</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="receiveAdd"
                                    name="receiveAdd"
                                    autoComplete="off"
                                    onChange={(e) => setReceiveAddress(e.target.value)}
                                    value={receiveAddress}
                                    aria-describedby="receiveAdd"
                                    style={{ padding: '8px 12px' }}
                                    disabled
                                />
                            </div>
                            <div className="pb-3 input-modal col-md-6">
                                <label htmlFor="pickupShipper" className="input-label-modal pb-3">NHÂN VIÊN NHẬN HÀNG</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="pickupShipper"
                                    name="pickupShipper"
                                    autoComplete="off"
                                    onChange={(e) => setPickupShipper(e.target.value)}
                                    value={pickupShipper}
                                    aria-describedby="pickupShipper"
                                    style={{ padding: '8px 12px' }}
                                    disabled
                                />
                            </div>
                            <div className="pb-3 input-modal col-md-6">
                                <label htmlFor="deliveryShipper" className="input-label-modal pb-3">NHÂN VIÊN GIAO HÀNG</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="deliveryShipper"
                                    name="deliveryShipper"
                                    autoComplete="off"
                                    onChange={(e) => setDeliveryShipper(e.target.value)}
                                    value={deliveryShipper}
                                    aria-describedby="deliveryShipper"
                                    style={{ padding: '8px 12px' }}
                                    disabled
                                />
                            </div>
                            <div className="mb-3 input-modal">
                                <label htmlFor="price" className="input-label-modal mb-3">THÀNH TIỀN</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="price"
                                    name="price"
                                    autoComplete="off"
                                    onChange={(e) => setPrice(e.target.value)}
                                    value={Number(price).toLocaleString()}
                                    aria-describedby="price"
                                    style={{ padding: '8px 12px' }}
                                    disabled
                                />
                            </div>
                        </section>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        onClick={() => {
                            setShow(false);
                            // setShowModalEditOrder(true);
                        }}
                    >
                        Đóng
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )

}

export default OrderDetail;