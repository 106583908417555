import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useEffect } from "react";

const RequireAuth = ({ allowedRoles }) => {
    const accessToken = localStorage.getItem("accessToken");
    const role = localStorage.getItem("role");
    const location = useLocation();

    useEffect(() => {
    },[])

    return (
        allowedRoles.includes(role)
            ? <Outlet />
            : accessToken
                ? <Navigate to="/unauthorized" state={{from: location}} replace />
                : <Navigate to="/" state={{from: location}} replace />
    );
}

export default RequireAuth;