import React, { useEffect, useRef, useState, useContext } from "react";
import Banner from "../layout/Banner";
import OTPInput from "react-otp-input";
import { sha256 } from "js-sha256";
import { getOtpApi } from "../apiCaller/services";
import { useNavigate } from "react-router-dom";

const VerifyCode = (props) => {

    const errRef = useRef();

    const navigate = useNavigate()
    const [errMsg, setErrMsg] = useState('');
    const [otp, setOtp] = useState("");
    const [email, setEmail] = useState("");
    const [otpError, setOtpError] = useState(null);
    const [timeLeft, setTimeLeft] = useState(600);
    const [correctOTP, setCorrectOTP] = useState("");
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
    const [isResendDisabled, setIsResendDisabled] = useState(true);

    useEffect(() => {
        setCorrectOTP(localStorage.getItem("OTP"));
        setEmail(localStorage.getItem("email"));
    }, []);

    const fetchOTP = async () => {
        try {
            const res = await getOtpApi(email);
            if (res && res.code === 200) {
                localStorage.setItem("OTP", sha256(res.verifyCode));
                localStorage.setItem("email", email);
            }
        } catch (err) {

        }
    };

    useEffect(() => {
        if (("" + otp) !== "" && sha256(""+otp) !== correctOTP) {
            setOtpError("❌ Wrong OTP Please Check Again");
            setIsSubmitDisabled(true);
        } else {
            setOtpError(null);
            setIsSubmitDisabled(false);
        }
    }, [otp]);

    useEffect(() => {
        if (timeLeft > 0) {
            const timer = setTimeout(() => {
                setTimeLeft(timeLeft - 1);
            }, 1000);
            return () => clearTimeout(timer);
        } else {
            setCorrectOTP("");
            setOtpError("❌ Time Limit Exceeded");
        }
    }, [timeLeft]);

    const handleChange = (enteredOtp) => {
        setOtp(enteredOtp);
    };

    const handleSubmit = async (e) => {
        if (sha256(otp) === correctOTP) {
            navigate('/change-password')
        }
    }

    const ResendCode = () => {
        fetchOTP();
        setTimeLeft(600);
        setIsResendDisabled(true);
    }

    return (
        <section>
            <div className="row container-fluid m-0 p-0 w-100 h-100">
                <div className="container-fluid col-md-6 col-sm-12 h-100" style={{ marginRight: 0 }}>
                    <div className="w-75 m-auto">
                        <div className="d-flex align-items-center mt-5">
                            <img src="./images/TT-01.png" className="" style={{ width: '50px', height: '50px' }} alt="Logo" />
                            <span className="ms-3" style={{ color: '#FF6827', lineHeight: '44px', fontSize: '36px', fontFamily: 'Inter', fontWeight: 600, wordWrap: 'break-word' }}>Hand2Hand</span>
                        </div>
                        <div className="text-start pt-5">
                            <h1 style={{ color: '#FF6827', lineHeight: '43px', fontSize: '36px', fontFamily: 'Inter', fontWeight: 700, wordWrap: 'break-word' }}>
                                Hệ thống quản lí giao nhận hàng
                            </h1>
                        </div>
                        <div className="text-start pt-5 my-5">
                            <p style={{ color: '#000000', lineHeight: '22px', fontSize: '18px', fontFamily: 'Inter', fontWeight: 400, wordWrap: 'break-word' }}>
                                Vui lòng nhập mã xác nhận vừa gửi đến địa chỉ Email vừa nhập
                            </p>
                        </div>
                        <div className="">
                            <div className="" style={{ marginTop: '4rem' }}>
                                <div>
                                    <p
                                        ref={errRef}
                                        className={errMsg ? "errmsg" : "offscreen"}
                                        aria-live="assertive"
                                    >
                                        {errMsg}
                                    </p>
                                    <div className="mb-0 input-block">
                                        <label htmlFor="user" className="input-label">Mã xác nhận</label>
                                        <OTPInput
                                            value={otp}
                                            placeholder="000000"
                                            onChange={handleChange}
                                            numInputs={6}
                                            renderSeparator={<span>-</span>}
                                            renderInput={(props) => <input {...props} />}
                                        />
                                        {otpError && <p>{otpError}</p>}
                                        {timeLeft > 0 && <p>Time Left: {Math.floor(timeLeft / 60)}:{String(timeLeft % 60).padStart(2, '0')}</p>}
                                    </div>
                                    <button onClick={handleSubmit} className="btn fs-3 mb-5 mt-5" style={{ width: '100%', backgroundColor: '#FF6827', borderRadius: '100px', color: '#ffffff' }} disabled={isSubmitDisabled}>Xác nhận</button>
                                    <div className="d-flex" style={{ fontSize: '18px' }}>
                                        <p>Bạn chưa nhận được mã xác nhận?&nbsp;</p>
                                        <button onClick={ResendCode} className="pb-3" style={{color: 'red', border: 'none', backgroundColor: 'white'}} disabled={isResendDisabled}>Gửi lại</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="" style={{ fontSize: '16px' }}>
                            <a href="/" className="" style={{ color: '#FF6827', fontSize: '18px' }}>Đăng nhập</a>
                        </div>
                    </div>
                    <div className="row text-center my-5 fs-3">
                        <div className="col-4 d-flex flex-row-reverse">
                            <a href="" className="text-danger">@H2H</a>
                        </div>
                        <div className="col-4 fs-3">
                            <a href="" className="text-danger">Contact</a>
                        </div>
                        <div className="col-4 d-flex flex-row fs-3">
                            <a href="" className="text-danger">Privacy & Terms</a>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 banner-hide m-0 p-0">
                    <Banner />
                </div>
            </div>
        </section>
    );
}

export default VerifyCode;