import _ from "lodash";
import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import QRCode from 'react-qr-code';

const QrDetail = (props) => {

    const { qrDetail, show, setShow } = props;
    const [qrId, setQrId] = useState("");
    const [qrValue, setQrValue] = useState("");
    const [orderId, setOrderId] = useState("");
    const [price, setPrice] = useState("");
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!_.isEmpty(qrDetail)) {
            setQrId(qrDetail.codeId);
            setQrValue(qrDetail.codeValue);
            qrDetail.orderId === null? setOrderId("") : setOrderId(qrDetail.orderId);
            setPrice(qrDetail.price);
            setLoading(false);
        }
    }, [qrDetail])

    return (
        <>
            <Modal
                show={show}
                backdrop="static"
                onHide={() => {
                    setShow(false);
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Chi tiết QR</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="">
                        {loading && <div>Loading...</div>}
                        <section>
                            <div className="text-center">
                                <QRCode
                                    value={qrDetail.codeValue || ''}
                                    style={{ height: "auto", width: "128px" }}
                                />
                            </div>
                            <div className="mb-3 input-modal">
                                <label htmlFor="qrcode" className="input-label-modal mb-3">MÃ QR</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="qrcode"
                                    name="qrcode"
                                    autoComplete="off"
                                    value={qrId}
                                    aria-describedby="qrcode"
                                    style={{ padding: '8px 12px' }}
                                    disabled
                                />
                            </div>
                            <div className="mb-3 input-modal">
                                <label htmlFor="qrstring" className="input-label-modal mb-3">CHUỖI QR</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="qrstring"
                                    name="qrstring"
                                    autoComplete="off"
                                    value={qrValue}
                                    aria-describedby="qrstring"
                                    style={{ padding: '8px 12px' }}
                                    disabled
                                />
                            </div>
                            <div className="mb-3 input-modal">
                                <label htmlFor="order" className="input-label-modal mb-3">ĐƠN HÀNG</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="order"
                                    name="order"
                                    autoComplete="off"
                                    value={orderId}
                                    aria-describedby="order"
                                    style={{ padding: '8px 12px' }}
                                    disabled
                                />
                            </div>
                            <div className="mb-3 input-modal">
                                <label htmlFor="money" className="input-label-modal mb-3">THÀNH TIỀN</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="money"
                                    name="money"
                                    autoComplete="off"
                                    value={Number(price).toLocaleString()}
                                    aria-describedby="money"
                                    style={{ padding: '8px 12px' }}
                                    disabled
                                />
                            </div>
                        </section>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={() => {
                        setShow(false);
                    }}
                    >
                        Đóng
                    </Button>
                    {/* <Button variant="primary"
                        onClick={() => {
                            setShow(false);
                            setShowModalEditQr(true);
                        }}>
                        Chỉnh sửa QR
                    </Button> */}
                </Modal.Footer>
            </Modal>
        </>
    )

}

export default QrDetail;