import React, { useEffect, useRef, useState } from "react";
import Banner from "../layout/Banner";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { getOtpApi } from "../apiCaller/services";
import { sha256 } from "js-sha256";

const SendMail = () => {

    const emailRef = useRef();
    const errRef = useRef();
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [isDisabled, setIsDisabled] = useState(true);

    const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    const isValidPassword = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,32}$/g;

    const validateEmail = (value) => {
        return value.match(isValidEmail)
    }

    const validatePassword = (password) => {
        return password.match(isValidPassword);
    };

    const fetchOTP = async () => {
        try {
            const res = await getOtpApi(email);
            if (res && res.code === 200) {
                localStorage.setItem("OTP", sha256(res.verifyCode));
                localStorage.setItem("email", email);
            }
        } catch (err) {

        }
    }

    useEffect(() => {
        emailRef.current.focus();
    }, [])

    const handleSubmit = async () => {
        if (validateEmail(email)) {
            fetchOTP();
            navigate('/verification-code');
        } else {
            toast.warn("Đừng F12 nữa, hãy nhập đúng email đi!");
        }
    }

    return (
        <section>
            <div className="row container-fluid m-0 p-0 w-100 h-100">
                <div className="container-fluid col-md-6 col-sm-12 h-100" style={{ marginRight: 0 }}>
                    <div className="w-75 m-auto">
                        <div className="d-flex align-items-center mt-5">
                            <img src="./images/TT-01.png" className="" style={{ width: '50px', height: '50px' }} alt="Logo" />
                            <span className="ms-3" style={{ color: '#FF6827', lineHeight: '44px', fontSize: '36px', fontFamily: 'Inter', fontWeight: 600, wordWrap: 'break-word' }}>Hand2Hand</span>
                        </div>
                        <div className="text-start pt-5">
                            <h1 style={{ color: '#FF6827', lineHeight: '43px', fontSize: '36px', fontFamily: 'Inter', fontWeight: 700, wordWrap: 'break-word' }}>
                                Hệ thống quản lí giao nhận hàng
                            </h1>
                        </div>
                        <div className="text-start pt-5 my-5">
                            <p style={{ color: '#000000', lineHeight: '22px', fontSize: '18px', fontFamily: 'Inter', fontWeight: 400, wordWrap: 'break-word' }}>
                                Vui lòng nhập địa chỉ Email để lấy lại mật khẩu
                            </p>
                        </div>
                        <div className="">
                            <div className="" style={{ marginTop: '4rem' }}>
                                <div>
                                    <p
                                        ref={errRef}
                                        className={errMsg ? "errmsg" : "offscreen"}
                                        aria-live="assertive"
                                    >
                                        {errMsg}
                                    </p>
                                    <div className="mb-0 input-block">
                                        <label htmlFor="email" className="input-label">Email</label>
                                        <input
                                            type="email"
                                            className="form-control fs-3"
                                            id="email"
                                            name="email"
                                            ref={emailRef}
                                            autoComplete="off"
                                            onChange={(e) => {
                                                setEmail(e.target.value);
                                                if (!validateEmail(e.target.value)) {
                                                    setErrMsg("Định dạng email chưa đúng");
                                                    setIsDisabled(true);
                                                } else {
                                                    setErrMsg('');
                                                    setIsDisabled(false);
                                                }
                                            }}
                                            value={email}
                                            aria-describedby="email"
                                            placeholder="Nhập email"
                                            required
                                        />
                                        <p className="text-danger mt-2" style={{ fontSize: '14px' }}>{errMsg}</p>
                                    </div>
                                    <button className="btn fs-3 mb-5 mt-5" onClick={handleSubmit} style={{ width: '100%', backgroundColor: '#FF6827', borderRadius: '100px', color: '#ffffff' }} disabled={isDisabled}>Lấy OTP</button>
                                </div>
                            </div>
                        </div>
                        <div className="" style={{ fontSize: '16px' }}>
                            <a href="/" className="" style={{ color: '#FF6827' }}>Đăng nhập</a>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 banner-hide m-0 p-0">
                    <Banner />
                </div>
            </div>
        </section>
    );
}

export default SendMail;