import Header from "../../layout/Header";
import Sidebar from "../../layout/SideBar";
import React, { useEffect, useState } from 'react';
import { managerOrderReportGraphApi, managerOrderReportTableApi } from '../../apiCaller/services.js';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Table } from "react-bootstrap";
import ReactPaginate from "react-paginate";
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const MOrderReport = () => {

    const accessToken = localStorage.getItem("accessToken");
    const [orderPickupLine, setOrderOrderPickupLine] = useState({});
    const [orderDeliveryLine, setOrderOrderDeliveryLine] = useState({});
    const [orderList, setOrderList] = useState([]);
    const [pageCount, setPageCount] = useState(1);
    const [month, setMonth] = useState(Number((new Date()).getMonth() + 1));

    const fetchOrder = async (page, month) => {
        try {
            const orgData = await managerOrderReportGraphApi(accessToken);
            const orgTable = await managerOrderReportTableApi(page, month, accessToken);
            const opArray = [];
            const odArray = [];

            setOrderList(orgTable?.data);
            setPageCount(0);
            orgData.map((item) => (
                opArray.push(item?.data?.pickup),
                odArray.push(item?.data?.diliver)
            ))
            setOrderOrderPickupLine(opArray);
            setOrderOrderDeliveryLine(odArray);
        } catch (err) {

        }
    }

    useEffect(() => {
        fetchOrder(1, month);
    }, []);

    const options = {
        responsive: true,
        plugins: {
            legend: {
                // position: 'top' as const,
            },
            title: {
                display: true,
                text: 'Báo cáo doanh thu',
            },
        },
        scales: {
            y: {
                beginAtZero: true,
                display: true,
                ticks: {
                    maxTicksLimit: 8
                }
            }
        }
    };

    const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    const data = {
        labels,
        datasets: [
            {
                label: 'Đơn nhận',
                data: orderPickupLine,
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
                tension: 0.4
            },
            {
                label: 'Đơn giao',
                data: orderDeliveryLine,
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
                tension: 0.4
            },
        ],
    };

    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <a
            href=""
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
            style={{ color: '#000000' }}
        >
            {children}
        </a>
    ));

    const handlePageClick = (event) => {
        fetchOrder(+event.selected + 1, month);
    };

    let rows2;

    if (orderList && orderList?.length < 10) {
        rows2 = (
            <>
                {
                    [...Array(10 - orderList?.length)].map((_, i) => (
                        <tr style={{ height: '30px' }} key={orderList.length + i}>
                            <td></td>
                            <td></td>
                        </tr>
                    ))
                }
            </>
        );
    };

    return (
        <section>
            <div className="container-fluid w-100 row p-0 m-0" style={{ backgroundColor: '#FAFAFA' }}>
                <Header />
                <div className="row">
                    <Sidebar />
                    <div className="col-lg-10 col-md-8 p-5">
                        <div className="d-flex my-5">
                            <h1 className=''><a href="#/report-manager">Báo cáo</a> &gt;&nbsp;</h1><h2 className="p-2">Đơn hàng</h2>
                        </div>
                        <div>
                            <Line options={options} data={data} />
                            <div className='mx-5 my-3 row'>
                                <div className="col-lg-6"></div>
                                <div className='d-md-flex flex-row-reverse col-lg-6 py-4'>
                                    <div className="ms-0 ms-md-4 d-flex justify-content-end">
                                        <select
                                            value={month}
                                            onChange={e => {
                                                setMonth(Number(e.target.value));
                                                fetchOrder(1, Number(e.target.value));
                                            }}
                                            className='p-2 mt-md-0 mt-3'
                                            style={{ fontSize: '16px' }}
                                        >
                                            <option value={"1"}>Tháng 1</option>
                                            <option value={"2"}>Tháng 2</option>
                                            <option value={"3"}>Tháng 3</option>
                                            <option value={"4"}>Tháng 4</option>
                                            <option value={"5"}>Tháng 5</option>
                                            <option value={"6"}>Tháng 6</option>
                                            <option value={"7"}>Tháng 7</option>
                                            <option value={"8"}>Tháng 8</option>
                                            <option value={"9"}>Tháng 9</option>
                                            <option value={"10"}>Tháng 10</option>
                                            <option value={"11"}>Tháng 11</option>
                                            <option value={"12"}>Tháng 12</option>
                                        </select>
                                    </div>
                                    <div className="d-flex justify-content-end mt-3 mt-md-0">
                                        <ReactPaginate
                                            breakLabel="..."
                                            nextLabel=">"
                                            onPageChange={handlePageClick}
                                            pageRangeDisplayed={20}
                                            pageCount={pageCount}
                                            previousLabel="<"
                                            renderOnZeroPageCount={null}
                                            marginPagesDisplayed={2}
                                            pageClassName="page-item"
                                            pageLinkClassName="page-link"
                                            previousClassName="page-item"
                                            previousLinkClassName="page-link"
                                            nextClassName="page-item"
                                            nextLinkClassName="page-link"
                                            breakClassName="page-item"
                                            breakLinkClassName="page-link"
                                            containerClassName="pagination"
                                            activeClassName="active"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='' style={{ backgroundColor: '#FFFFFF' }}>
                                <Table responsive bordered cellSpacing={0}>
                                    <thead>
                                        <tr>
                                            <th style={{ width: '15%' }}>MÃ ĐƠN HÀNG</th>
                                            <th style={{ width: '15%' }}>LOẠI ĐƠN</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {orderList?.map((item, index) => (
                                            <tr height='30px' key={`request-${index}`}>
                                                <td>{item?.orderId}</td>
                                                <td>{item?.type==="pickup"?"Đơn nhận":"Đơn giao"}</td>
                                            </tr>
                                        ))}
                                        {rows2}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    )
}

export default MOrderReport;