import { useEffect, useState } from "react"
import Header from "../../layout/Header"
import Sidebar from "../../layout/SideBar"
import { getAdminDashBoard } from "../../apiCaller/services";

const ReportAdmin = () => {

    const [revenueTotal, setRevenueTotal] = useState(0);
    const [customerTotal, setCustomerTotal] = useState(0);
    const [orderTotal, setOrderTotal] = useState(0);
    const [staffTotal, setStaffTotal] = useState(0);
    const [warehouseTotal, setWarehouseList] = useState(0);
    const [areaTotal, setAreaTotal] = useState(0);
    const [bestWh, setBestWh] = useState("");
    const [bestWhRevenue, setBestWhRevenue] = useState("");
    const [bestArea, setBestArea] = useState("");
    const [bestAreaRevenue, setBestAreaRevenue] = useState("");
    const accessToken = localStorage.getItem("accessToken")

    const fetchAdminDashBoard = async () => {
        const DbData = await getAdminDashBoard(accessToken);
        setRevenueTotal(DbData?.revenue?.totalrevenue);
        setCustomerTotal(DbData?.customers);
        setOrderTotal(DbData?.orders);
        setStaffTotal(DbData?.staffs);
        setWarehouseList(DbData?.totalwareHouse);
        setAreaTotal(DbData?.totalDistrict);
        setBestWh(DbData?.bestRevenueWarehouse?.warehouseName);
        setBestWhRevenue(DbData?.totalwareHouse?.revenue);
        setBestArea(DbData?.bestRevenueArea?.districtName);
        setBestAreaRevenue(DbData?.bestRevenueArea?.revenue);
    }

    useEffect(() => {
        fetchAdminDashBoard();
    }, []);

    const numberWithCommas = (x) => {
        return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    };

    return (
        <section>
            <div className="container-fluid w-100 row p-0 m-0">
                <Header />
                <div className="row">
                    <Sidebar />
                    <div className="col-lg-10 col-md-8 col-12 ps-5" style={{ backgroundColor: '#F5F5F5' }}>
                        <div className="mt-5">
                            <h1 className='my-5'>Báo cáo</h1>
                            <div className="row">
                                <div className="col-sm-6 col-lg-4 p-4">
                                    <div className="shadow-sm rounded">
                                        <h1>Doanh thu</h1>
                                        <div className="row mx-1" style={{ backgroundColor: '#FAFAFA' }}>
                                            {/* <img className="col-5" src="" alt="Report" /> */}
                                            <i className="fa-solid fa-money-bill-trend-up col-5" style={{ fontSize: '50px' }} ></i>
                                            <div className="col-7">
                                                <p style={{ fontSize: '20px' }}>{numberWithCommas(revenueTotal)?numberWithCommas(revenueTotal):0} VNĐ</p>
                                                <a href="#/revenue-report" style={{ fontSize: '14px' }}>Xem chi tiết</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-lg-4 p-4">
                                    <div className="shadow-sm rounded">
                                        <h1>Khách hàng</h1>
                                        <div className="row mx-1" style={{ backgroundColor: '#FAFAFA' }}>
                                            {/* <img className="col-5" src="" alt="Report" /> */}
                                            <i className="fa-solid fa-user col-5" style={{ fontSize: '50px' }}></i>
                                            <div className="col-7">
                                                <p style={{ fontSize: '20px' }}>{customerTotal}</p>
                                                <a href="#/customer-report" style={{ fontSize: '14px' }}>Xem chi tiết</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-lg-4 p-4">
                                    <div className="shadow-sm rounded">
                                        <h1>Đơn hàng</h1>
                                        <div className="row mx-1" style={{ backgroundColor: '#FAFAFA' }}>
                                            {/* <img className="col-5" src="" alt="Report" /> */}
                                            <i className="fa-solid fa-truck-fast col-5" style={{ fontSize: '50px' }} ></i>
                                            <div className="col-7">
                                                <p style={{ fontSize: '20px' }}>{orderTotal}</p>
                                                <a href="#/order-report" style={{ fontSize: '14px' }}>Xem chi tiết</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-lg-4 p-4">
                                    <div className="shadow-sm rounded">
                                        <h1>Nhân viên</h1>
                                        <div className="row mx-1" style={{ backgroundColor: '#FAFAFA' }}>
                                            {/* <img className="col-5" src="" alt="Report" /> */}
                                            <i className="fa-solid fa-users col-5" style={{ fontSize: '50px' }} ></i>
                                            <div className="col-7">
                                                <p style={{ fontSize: '20px' }}>{staffTotal}</p>
                                                <a href="#/staff-report" style={{ fontSize: '14px' }}>Xem chi tiết</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-lg-4 p-4">
                                    <div className="shadow-sm rounded">
                                        <h1>Kho</h1>
                                        <div className="row mx-1" style={{ backgroundColor: '#FAFAFA' }}>
                                            {/* <img className="col-5" src="" alt="Report" /> */}
                                            <i className="fa-brands fa-shopify col-5" style={{ fontSize: '50px' }} ></i>
                                            <div className="col-7">
                                                <p style={{ fontSize: '20px' }}>{warehouseTotal}</p>
                                                {/* <p style={{ fontSize: '20px' }}>Kho {bestWh} có doanh thu cao nhất {bestWhRevenue} VNĐ</p> */}
                                                <a href="#/system-setting" style={{ fontSize: '14px' }}>Xem chi tiết</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-lg-4 p-4">
                                    <div className="shadow-sm rounded">
                                        <h1>Khu vực</h1>
                                        <div className="row mx-1" style={{ backgroundColor: '#FAFAFA' }}>
                                            {/* <img className="col-5" src="" alt="Report" /> */}
                                            <i className="fa-solid fa-bookmark col-5" style={{ fontSize: '50px' }} ></i>
                                            <div className="col-7">
                                                <p style={{ fontSize: '20px' }}>{areaTotal}</p>
                                                {/* <p style={{ fontSize: '20px' }}>Kho {bestArea} có doanh thu cao nhất {bestAreaRevenue} VNĐ</p> */}
                                                <a href="#/area-report" style={{ fontSize: '14px' }}>Xem chi tiết</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    )
}

export default ReportAdmin;