import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { adminCustomerReportGraphApi } from '../../apiCaller/services';
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function CustomerChart() {

  const accessToken = localStorage.getItem("accessToken");

  const [customerReportGraph, setCustomerReportGraph] = useState([]);

  const fetchCustomerReportGraph = async () => {
    try {
      const crData = await adminCustomerReportGraphApi(accessToken);
      const newArray = [];
      crData.map((item) => (
        newArray.push(item?.totalRevenue)
      ))
      setCustomerReportGraph(newArray);
    } catch (err) {

    }
  }

  useEffect(() => {
    fetchCustomerReportGraph();
  }, [])

  const options = {
    responsive: true,
    plugins: {
      legend: {
        // position: 'top' as const,
      },
      title: {
        display: true,
        text: 'Báo cáo khách hàng',
      },
    },

    scales: {
      y: {
        beginAtZero: true,
        display: true,
        ticks: {
          maxTicksLimit: 8
        }
      }
    },
  };

  const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  const data = {
    labels,
    datasets: [
      {
        label: 'Khách hàng',
        data: customerReportGraph,
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
    ],
  };

  return (
    <Line options={options} data={data} />
  );

}
export default CustomerChart;
