import { useEffect, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { createStaffApi, getAccountDetailApi } from "../../apiCaller/services";
import { toast } from "react-toastify";

const CreateStaff = (props) => {

    const { show, setShow, fetchStaffList } = props;
    const [username, setUsername] = useState("");
    const [errUser, setErrUser] = useState("");
    const password = "Abcd@123";
    const [role, setRole] = useState(2);
    const [fullname, setFullname] = useState("");
    const [errFullname, setErrFullname] = useState("");
    const [email, setEmail] = useState("");
    const [errEmail, setErrEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [errPhone, setErrPhone] = useState("");
    const [warehouse, setWarehouse] = useState(0);
    const accessToken = localStorage.getItem("accessToken");

    const fetchManagerDetail = async () => {
        try {
            const managerDetail = await getAccountDetailApi(accessToken);
            setWarehouse(managerDetail?.data?.staff?.warehouseId);
        } catch (err) {

        }
    }

    const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    const isValidPhone = /^0[0-9]{9}$/g;

    const validatePhone = (value) => {
        if (value.match(isValidPhone)) {
            return true;
        } else {
            return false
        }
    }

    const validateEmail = (value) => {
        if (value.match(isValidEmail)) {
            return true;
        } else {
            return false
        }
    }

    const handleSubmit = async () => {
        const errors = {};

        if (username === "") errors.username = "Bạn chưa điền username";
        if (fullname === "") errors.fullname = "Bạn chưa điền tên đầy đủ";
        if (email === "") errors.email = "Bạn chưa điền email";
        else if (!validateEmail(email)) errors.email = "Định dạng email chưa đúng!";
        if (phone === "") errors.phone = "Bạn chưa điền số điện thoại";
        else if (!validatePhone(phone)) errors.phone = "Định dạng số điện thoại chưa đúng!";

        if (Object.keys(errors).length > 0) {
            setErrUser(errors.username || "");
            setErrFullname(errors.fullname || "");
            setErrEmail(errors.email || "");
            setErrPhone(errors.phone || "");
        } else {
            if (window.confirm(`Bạn có chắc muốn tạo nhân viên ${username} không?`)) {
                try {
                    const data = {
                        username: username,
                        password: password,
                        roleId: role,
                        fullName: fullname,
                        email: email,
                        phone: phone,
                        warehouseId: warehouse
                    };
                    const res = await createStaffApi(data, accessToken);
                    if (res && res.status === 200) {
                        toast.success(`Tạo nhân viên ${username} với vai trò ${role === 2 ? "Shipper" : "Staff"} thành công!`);
                        setShow(false);
                        setUsername("");
                        setRole(2);
                        setFullname("");
                        setEmail("");
                        setPhone("");
                        setErrUser("");
                        setErrFullname("");
                        setErrEmail("");
                        setErrPhone("");
                        fetchStaffList(1);
                    }
                } catch (err) {
                    // handle error
                }
            }
        }
    }

    useEffect(() => {
        fetchManagerDetail();
    },[])

    return (
        <>
            <Modal
                backdrop="static"
                show={show}
                onHide={() => {
                    setShow(false);
                    setUsername("");
                    setRole(2);
                    setFullname("");
                    setEmail("");
                    setPhone("");
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Tạo nhân viên</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="">
                        <section>
                            <div className="mb-3 edit-modal">
                                <label htmlFor="username" className="edit-label-modal mb-3">USERNAME</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="username"
                                    name="username"
                                    autoComplete="off"
                                    onChange={(e) => {
                                        setUsername(e.target.value);
                                        setErrUser("");
                                    }}
                                    value={username}
                                    aria-describedby="username"
                                    style={{ padding: '8px 12px' }}
                                    required
                                />
                                <p style={{ color: 'red' }}>{errUser}</p>
                            </div>
                            <div className="mb-3 edit-modal">
                                <label htmlFor="role" className="edit-label-modal mb-3">VAI TRÒ</label>
                                <select
                                    value={role}
                                    onChange={(e) => {
                                        setRole(+e.target.value);
                                    }}
                                    className="p-2"
                                    style={{fontSize: '16px'}}
                                >
                                    <option value="2">Shipper</option>
                                    <option value="3">Staff</option>
                                </select>
                            </div>
                            <div className="mb-3 edit-modal">
                                <label htmlFor="fullname" className="edit-label-modal mb-3">TÊN ĐẦY ĐỦ</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="fullname"
                                    name="fullname"
                                    autoComplete="off"
                                    onChange={(e) => {
                                        setFullname(e.target.value);
                                        setErrFullname("");
                                    }}
                                    value={fullname}
                                    aria-describedby="fullname"
                                    style={{ padding: '8px 12px' }}
                                    required
                                />
                                <p style={{ color: 'red' }}>{errFullname}</p>
                            </div>
                            <div className="mb-3 edit-modal">
                                <label htmlFor="email" className="edit-label-modal mb-3">EMAIL</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    id="email"
                                    name="email"
                                    autoComplete="off"
                                    onChange={(e) => {
                                        setEmail(e.target.value);
                                        setErrEmail("");
                                    }}
                                    value={email}
                                    aria-describedby="email"
                                    style={{ padding: '8px 12px' }}
                                    required
                                />
                                <p style={{ color: 'red' }}>{errEmail}</p>
                            </div>
                            <div className="mb-3 edit-modal">
                                <label htmlFor="phone" className="edit-label-modal mb-3">SỐ ĐIỆN THOẠI</label>
                                <input
                                    type="tel"
                                    className="form-control"
                                    id="phone"
                                    name="phone"
                                    autoComplete="off"
                                    onChange={(e) => {
                                        setPhone(e.target.value);
                                        setErrPhone("");
                                    }}
                                    value={phone}
                                    aria-describedby="phone"
                                    style={{ padding: '8px 12px' }}
                                    required
                                />
                                <p style={{ color: 'red' }}>{errPhone}</p>
                            </div>
                        </section>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleSubmit}>
                        Tạo nhân viên
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )

}

export default CreateStaff;