import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import "../App.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const Sidebar = () => {

  const role = localStorage.getItem('role');
  const [activeLink, setActiveLink] = useState('');
  const navigate = useNavigate();

  const getRoleLinks = (activeLink, setActiveLink) => {
    switch (role) {
      case 'ADMIN':
        return renderAdminLinks(activeLink, setActiveLink);
      case 'STAFF':
        return renderStaffLinks(activeLink, setActiveLink);
      case 'MANAGER':
        return renderManagerLinks(activeLink, setActiveLink);
      default:
        return;
    }
  };

  const renderAdminLinks = (activeLink, setActiveLink) => {
    return (
      <>
        <Navbar
          className="bg-body-tertiary"
          onClick={() => setActiveLink("report-admin")}
        >

          <Navbar.Brand href="#/report-admin" className={`sidebar-font ps-3 me-0 w-100 ${activeLink === 'reportadmin' ? 'active' : ''}`} style={{ color: "#CF6402" }}>
            <i className="fa-solid fa-chart-simple"></i> {"  "}
            Báo cáo
          </Navbar.Brand>

        </Navbar>
        <br />
        <Navbar
          className="bg-body-tertiary"
          onClick={() => setActiveLink("system-setting")}
        >

          <Navbar.Brand href="#/system-setting" className={`sidebar-font ps-3 me-0 w-100 ${activeLink === 'systemsetting' ? 'active' : ''}`} style={{ color: "#CF6402" }}>
            <i className="fa-solid fa-gear"></i> {"  "}
            Cài đặt hệ thống
          </Navbar.Brand>

        </Navbar>
        <br />
        <Navbar
          className="bg-body-tertiary"
          onClick={() => {
            setActiveLink("manage-staff");
          }}
        >

          <Navbar.Brand href="#/manage-staff" className={`sidebar-font ps-3 me-0 w-100 ${activeLink === 'stafflist' ? 'active' : ''}`} style={{ color: "#CF6402" }}>
            <i className="fa-solid fa-users"></i> {"  "}
            Quản lý nhân viên
          </Navbar.Brand>

        </Navbar>
        <br />
        <Navbar
          className="bg-body-tertiary"
          onClick={() => setActiveLink("revenue-report")}
        >

          <Navbar.Brand href="#/revenue-report" className={`sidebar-font ps-3 me-0 w-100 ${activeLink === 'revenuereport' ? 'active' : ''}`} style={{ color: "#CF6402" }}>
            <i className="fa-solid fa-money-bill"></i> {"  "}
            Báo cáo doanh thu
          </Navbar.Brand>

        </Navbar>
        <br />
        <Navbar
          className="bg-body-tertiary"
          onClick={() => setActiveLink("customer-report")}
        >

          <Navbar.Brand href="#/customer-report" className={`sidebar-font ps-3 me-0 w-100 ${activeLink === 'customerreport' ? 'active' : ''}`} style={{ color: "#CF6402" }}>
            <i className="fa-solid fa-user"></i> {"  "}
            Báo cáo khách hàng
          </Navbar.Brand>

        </Navbar>
        <br />
        <Navbar
          className="bg-body-tertiary"
          onClick={() => setActiveLink("order-report")}
        >

          <Navbar.Brand href="#/order-report" className={`sidebar-font ps-3 me-0 w-100 ${activeLink === 'orderreport' ? 'active' : ''}`} style={{ color: "#CF6402" }}>
            <i className="fa-solid fa-truck-fast"></i> {"  "}
            Báo cáo đơn hàng
          </Navbar.Brand>

        </Navbar>
        <br />
        <Navbar
          className="bg-body-tertiary"
          onClick={() => setActiveLink("staff-report")}
        >

          <Navbar.Brand href="#/staff-report" className={`sidebar-font ps-3 me-0 w-100 ${activeLink === 'staffreport' ? 'active' : ''}`} style={{ color: "#CF6402" }}>
            <i className="fa-solid fa-users"></i> {"  "}
            Báo cáo nhân viên
          </Navbar.Brand>

        </Navbar>
        <br />
        {/* <Navbar
          className="bg-body-tertiary"
          onClick={() => setActiveLink("warehouse-report")}
        >

          <Navbar.Brand href="warehouse-report" className={`sidebar-font ps-3 me-0 w-100 ${activeLink === 'warehousereport' ? 'active' : ''}`} style={{ color: "#CF6402" }}>
            <i className="fa-brands fa-shopify"></i> {"  "}
            Báo cáo kho
          </Navbar.Brand>

        </Navbar>
        <br /> */}
        <Navbar
          className="bg-body-tertiary"
          onClick={() => setActiveLink("area-report")}
        >

          <Navbar.Brand href="#/area-report" className={`sidebar-font ps-3 me-0 w-100 ${activeLink === 'areareport' ? 'active' : ''}`} style={{ color: "#CF6402" }}>
            <i className="fa-solid fa-bookmark"></i> {"  "}
            Báo cáo khu vực
          </Navbar.Brand>

        </Navbar>
      </>
    );
  };

  const renderManagerLinks = (activeLink, setActiveLink) => {
    return (
      <>
        <Navbar
          className="bg-body-tertiary"
          onClick={() => {
            setActiveLink("request");
          }}
        >
          <Navbar.Brand href="#/request" className={`sidebar-font ps-3 me-0 w-100 ${activeLink === 'requestlist' ? 'active' : ''}`} style={{ color: "#CF6402" }}>
            <i className="fa-solid fa-file"></i> {"  "}
            Yêu cầu
          </Navbar.Brand>
        </Navbar>
        <br />
        <Navbar
          className="bg-body-tertiary"
          onClick={() => {
            setActiveLink("order");
          }}
        >

          <Navbar.Brand href="#/order" className={`sidebar-font ps-3 me-0 w-100 ${activeLink === 'orderlist' ? 'active' : ''}`} style={{ color: "#CF6402" }}>
            <i className="fa-solid fa-truck-fast"></i> {"  "}
            Đơn hàng
          </Navbar.Brand>

        </Navbar>
        <br />
        <Navbar
          className="bg-body-tertiary"
          onClick={() => {
            setActiveLink("qr");
          }}
        >

          <Navbar.Brand href="#/qr" className={`sidebar-font ps-3 me-0 w-100 ${activeLink === 'qrlist' ? 'active' : ''}`} style={{ color: "#CF6402" }}>
            <i className="fa-solid fa-barcode"></i> {"  "}
            Mã QR
          </Navbar.Brand>

        </Navbar>
        {/* <br /> */}
        {/* <Navbar
          className="bg-body-tertiary"
          onClick={() => setActiveLink("shiftmanagement")}
        >

          <Navbar.Brand href="shiftmanagement" className={`sidebar-font ps-3 me-0 w-100 ${activeLink === 'shiftmanagement' ? 'active' : ''}`} style={{ color: "#CF6402" }}>
            <i className="fa-solid fa-truck-fast"></i> {"  "}
            Ca làm
          </Navbar.Brand>

        </Navbar> */}
        <br />

        <Navbar
          className="bg-body-tertiary"
          onClick={() => {
            setActiveLink("staff");
          }}
        >

          <Navbar.Brand href="#/staff" className={`sidebar-font ps-3 me-0 w-100 ${activeLink === 'stafflist' ? 'active' : ''}`} style={{ color: "#CF6402" }}>
            <i className="fa-solid fa-users"></i> {"  "}
            Nhân viên
          </Navbar.Brand>

        </Navbar>
        <br />
        <Navbar
          className="bg-body-tertiary"
          onClick={() => {
            setActiveLink("customer");
          }}
        >

          <Navbar.Brand href="#/customer" className={`sidebar-font ps-3 me-0 w-100 ${activeLink === 'customerlist' ? 'active' : ''}`} style={{ color: "#CF6402" }}>
            <i className="fa-solid fa-user"></i> {"  "}
            Khách hàng
          </Navbar.Brand>

        </Navbar>
        <br />
        <Navbar
          className="bg-body-tertiary"
          onClick={() => {
            setActiveLink("report-manager");
          }}
        >

          <Navbar.Brand href="#/report-manager" className={`sidebar-font ps-3 me-0 w-100 ${activeLink === 'reportmanager' ? 'active' : ''}`} style={{ color: "#CF6402" }}>
            <i className="fa-solid fa-chart-simple"></i> {"  "}
            Báo cáo
          </Navbar.Brand>

        </Navbar>
        <br />
      </>
    );
  };

  const renderStaffLinks = (activeLink, setActiveLink) => {
    return (
      <>
        <Navbar
          className="bg-body-tertiary"
          onClick={() => {
            setActiveLink("request");
          }}
        >
          <Container>
            <Navbar.Brand href="#/request" className={`sidebar-font ps-3 me-0 w-100 ${activeLink === 'reportmanager' ? 'active' : ''}`} style={{ color: "#CF6402" }}>
              <i className="fa-solid fa-file"></i> {"  "}
              Yêu cầu
            </Navbar.Brand>
          </Container>
        </Navbar>
        <br />
        <Navbar
          className="bg-body-tertiary"
          onClick={() => {
            setActiveLink("order");
          }}
        >
          <Container>
            <Navbar.Brand href="#/order" className={`sidebar-font ps-3 me-0 w-100 ${activeLink === 'reportmanager' ? 'active' : ''}`} style={{ color: "#CF6402" }}>
              <i className="fa-solid fa-truck-fast"></i> {"  "}
              Đơn hàng
            </Navbar.Brand>
          </Container>
        </Navbar>
        <br />
        <Navbar
          className="bg-body-tertiary"
          onClick={() => {
            setActiveLink("qr");
          }}
        >
          <Container>
            <Navbar.Brand href="#/qr" className={`sidebar-font ps-3 me-0 w-100 ${activeLink === 'reportmanager' ? 'active' : ''}`} style={{ color: "#CF6402" }}>
              <i className="fa-solid fa-barcode"></i> {"  "}
              Mã QR
            </Navbar.Brand>
          </Container>
        </Navbar>
        <br />
        {/* <Navbar
          className="bg-body-tertiary"
          onClick={() => setActiveLink("shiftmanagement")}
        >
          <Container>
            <Navbar.Brand href="shiftmanagement" className={`sidebar-font ps-3 me-0 w-100 ${activeLink === 'reportmanager' ? 'active' : ''}`} style={{ color: "#CF6402" }}>
              <i className="fa-solid fa-truck-fast"></i> {"  "}
              Ca làm
            </Navbar.Brand>
          </Container>
        </Navbar>
        <br /> */}
      </>
    );
  };

  return (
    <>
      <div
        className="col-lg-2 col-md-4"
        style={{ backgroundColor: "#ffffff" }}
      >
        <div className="" style={{ position: 'sticky', top: '100px' }}>
          {getRoleLinks(activeLink, setActiveLink)}
        </div>
      </div>
    </>
  );
}

export default Sidebar;