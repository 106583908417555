import _ from "lodash";
import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";

const StaffManagementDetail = (props) => {

    const { show, setShow, staffDetail } = props;
    const [staffId, setStaffId] = useState("");
    const [role, setRole] = useState("");
    const [wh, setWh] = useState("");
    const [fullname, setFullname] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [address, setAddress] = useState("");
    const [status, setStatus] = useState("");

    useEffect(() => {

        if (!_.isEmpty(staffDetail)) {
            setStaffId(staffDetail?.s_staff_id);
            setFullname(staffDetail?.s_fullname);
            setRole(staffDetail?.role_name);
            setWh(staffDetail?.warehouse_warehouse_name);
            setEmail(staffDetail?.s_email);
            setPhone(staffDetail?.s_phone);
            setAddress(`${staffDetail?.address_house}, ${staffDetail?.ward_name}, ${staffDetail?.district_name}, ${staffDetail?.city_name}`);
            setStatus(staffDetail?.statusname);
        }
    }, [staffDetail]);

    return (
        <>
            <Modal show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Chi tiết nhân viên</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="">
                        <section>
                            <div className="mb-3 edit-modal">
                                <label htmlFor="staffId" className="edit-label-modal mb-3">MÃ NHÂN VIÊN</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="staffId"
                                    name="staffId"
                                    autoComplete="off"
                                    value={staffId}
                                    aria-describedby="staffId"
                                    style={{ padding: '8px 12px' }}
                                    disabled
                                />
                            </div>
                            <div className="mb-3 edit-modal">
                                <label htmlFor="fullname" className="edit-label-modal mb-3">TÊN ĐẦY ĐỦ</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="fullname"
                                    name="fullname"
                                    autoComplete="off"
                                    value={fullname}
                                    aria-describedby="fullname"
                                    style={{ padding: '8px 12px' }}
                                    disabled
                                />
                            </div>
                            <div className="mb-3 edit-modal">
                                <label htmlFor="role" className="edit-label-modal mb-3">VAI TRÒ</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="role"
                                    name="role"
                                    autoComplete="off"
                                    value={role}
                                    aria-describedby="role"
                                    style={{ padding: '8px 12px' }}
                                    disabled
                                />
                            </div>
                            <div className="mb-3 edit-modal">
                                <label htmlFor="warehouse" className="edit-label-modal mb-3">KHO</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="warehouse"
                                    name="warehouse"
                                    autoComplete="off"
                                    value={wh}
                                    aria-describedby="warehouse"
                                    style={{ padding: '8px 12px' }}
                                    disabled
                                />
                            </div>
                            <div className="mb-3 edit-modal">
                                <label htmlFor="email" className="edit-label-modal mb-3">EMAIL</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    id="email"
                                    name="email"
                                    autoComplete="off"
                                    value={email}
                                    aria-describedby="email"
                                    style={{ padding: '8px 12px' }}
                                    disabled
                                />
                            </div>
                            <div className="mb-3 edit-modal">
                                <label htmlFor="phone" className="edit-label-modal mb-3">SỐ ĐIỆN THOẠI</label>
                                <input
                                    type="tel"
                                    className="form-control"
                                    id="phone"
                                    name="phone"
                                    autoComplete="off"
                                    value={phone}
                                    aria-describedby="phone"
                                    style={{ padding: '8px 12px' }}
                                    disabled
                                />
                            </div>
                            <div className="mb-3 edit-modal">
                                <label htmlFor="address" className="edit-label-modal mb-3">ĐỊA CHỈ</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="address"
                                    name="address"
                                    autoComplete="off"
                                    value={address}
                                    aria-describedby="address"
                                    style={{ padding: '8px 12px' }}
                                    disabled
                                />
                            </div>
                            <div className="mb-3 edit-modal">
                                <label htmlFor="status" className="edit-label-modal mb-3">TRẠNG THÁI</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="status"
                                    name="status"
                                    autoComplete="off"
                                    value={status}
                                    aria-describedby="status"
                                    style={{ padding: '8px 12px' }}
                                    disabled
                                />
                            </div>
                        </section>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => setShow(false)}>
                        Đóng
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )

}

export default StaffManagementDetail;